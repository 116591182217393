import { useCheckCapabilities } from '@/api/queryHooks/useMerchantCryptoController';
import { useReadLocalStorage } from 'usehooks-ts';
import { ACCESS_TOKEN } from '@/constants/storage';
import { useRole } from '@/hooks/useRole';
import { Roles } from '@/constants/roles';

export const useIsMerchant = () => {
  const accessToken = useReadLocalStorage(ACCESS_TOKEN);
  const role = useRole();
  const { data } = useCheckCapabilities([{ accessToken: accessToken as string }, { enabled: role === Roles.BUSINESS }]);

  return data?.isMerchant;
};
