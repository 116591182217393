import React, { CSSProperties, RefObject, useRef } from 'react';
import { useListBox, useOption, AriaListBoxOptions } from '@react-aria/listbox';
import { ListState, useListState } from '@react-stately/list';
import clsx from 'clsx';
import { AriaListBoxProps } from '@react-types/listbox';
import type { Node } from '@react-types/shared';
import styles from './SelectList.module.css';
import { RadioGroup, Radio } from '../Radio';
import { Icon, IconType } from '../../Icon';
import { Button } from '../../buttons';

export { Item as SelectListItem } from '@react-stately/collections';

export interface SelectListBoxProps extends AriaListBoxOptions<unknown> {
  className?: string;
  listBoxRef?: RefObject<HTMLUListElement>;
  state: ListState<unknown>;
  maxHeightScroll?: string;
  type?: 'single' | 'radio' | 'radioWithIcon';
  icon?: IconType;
  iconName?: IconType;
  logout?: boolean;
  onLogoutPress?: () => void;
}

export interface SelectListProps extends AriaListBoxOptions<HTMLUListElement>, AriaListBoxProps<HTMLUListElement> {
  className?: string;
  style?: CSSProperties;
  maxHeightScroll?: string;
  type?: 'single' | 'radio' | 'radioWithIcon';
  icon?: IconType;
  iconName?: IconType;
  logout?: boolean;
  onLogoutPress?: () => void;
}

export interface SelectListOptionProps {
  className?: string;
  item: Node<unknown>;
  state: ListState<unknown>;
  icon?: IconType;
  logout?: boolean;
}

export interface SelectListRadioProps extends SelectListOptionProps {
  size?: 'medium' | 'large';
  iconName?: IconType;
  ariaLebel?: string;
}

export const SelectListOption = function SelectListOption({
  item,
  state,
  className,
  icon,
  logout,
}: SelectListOptionProps) {
  const ref = useRef<HTMLLIElement>(null);
  // eslint-disable-next-line operator-linebreak
  const { optionProps, isSelected, isFocused, isPressed, isDisabled } = useOption({ key: item.key }, state, ref);

  return (
    <li
      {...optionProps}
      className={clsx(
        styles.option,
        {
          [styles.isFocused]: isFocused,
          [styles.isSelected]: isSelected,
          [styles.isPressed]: isPressed,
          [styles.isDisabled]: isDisabled,
          [styles.withIcon]: icon,
          [styles.logoutOption]: logout,
        },
        className,
      )}
      ref={ref}
    >
      {icon && <Icon name={icon} size={18} className={styles.singleOptionIcon} />}
      {item.rendered}
    </li>
  );
};

export const SelectListRadio = function SelectListRadio({ item, state, size }: SelectListRadioProps) {
  const ref = useRef<HTMLLIElement>(null);
  const { optionProps } = useOption({ key: item.key }, state, ref);

  return (
    <li {...optionProps} className={styles.option} ref={ref}>
      <Radio
        size={size}
        value={String(item.rendered)}
        className={styles.radioLabel}
        contentClassName={styles.radioName}
      >
        {item.rendered}
      </Radio>
    </li>
  );
};

export const SelectListRadioWithIcon = function SelectListRadioWithIcon({
  item,
  state,
  size,
  icon,
  iconName,
}: SelectListRadioProps) {
  const ref = useRef<HTMLLIElement>(null);
  const { optionProps } = useOption({ key: item.key }, state, ref);
  return (
    <li {...optionProps} className={styles.option} ref={ref}>
      <Radio
        size={size}
        value={String(item.rendered)}
        className={styles.radioLabel}
        contentClassName={styles.radioNameWithIcon}
        icon={icon}
        iconName={iconName}
        iconSize={18}
      >
        {item.rendered}
      </Radio>
    </li>
  );
};

export const SelectListBox = function SelectListBox({
  className,
  maxHeightScroll,
  type,
  icon,
  iconName,
  logout,
  onLogoutPress,
  ...props
}: SelectListBoxProps) {
  const ref = useRef<HTMLUListElement>(null);
  const { listBoxRef = ref, state } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  switch (type) {
    case 'radio':
      return (
        <>
          <ul
            {...listBoxProps}
            style={{ maxHeight: maxHeightScroll }}
            className={clsx(styles.listContainer, { [styles.isScrollable]: maxHeightScroll }, className)}
            ref={listBoxRef}
          >
            <RadioGroup>
              {[...state.collection].map((item) => (
                <SelectListRadio key={item.key} item={item} state={state} />
              ))}
            </RadioGroup>
          </ul>
          {maxHeightScroll && <div className={styles.listBlur} />}
        </>
      );
    case 'radioWithIcon':
      return (
        <RadioGroup>
          <>
            <ul
              {...listBoxProps}
              style={{ maxHeight: maxHeightScroll }}
              className={clsx(styles.listContainer, { [styles.isScrollable]: maxHeightScroll }, className)}
              ref={listBoxRef}
            >
              {[...state.collection].map((item) => (
                <SelectListRadioWithIcon key={item.key} item={item} state={state} iconName={iconName} icon={icon} />
              ))}
            </ul>
            {maxHeightScroll && <div className={styles.listBlur} />}
          </>
        </RadioGroup>
      );
    default:
      return (
        <>
          <ul
            {...listBoxProps}
            style={{ maxHeight: maxHeightScroll }}
            className={clsx(styles.listContainer, { [styles.isScrollable]: maxHeightScroll }, className)}
            ref={listBoxRef}
          >
            {[...state.collection].map((item) => (
              <SelectListOption key={item.key} item={item} state={state} icon={icon} logout={logout} />
            ))}
            {logout && (
              <li className={styles.lastOptionWrapper}>
                <Button
                  variants="subtle"
                  className={clsx(styles.option, styles.lastOption)}
                  icon="logOut"
                  iconClassName={styles.logoutIcon}
                  onPress={onLogoutPress}
                >
                  <span className={styles.logoutLabel}>Log Out</span>
                </Button>
              </li>
            )}
          </ul>
          {maxHeightScroll && <div className={styles.listBlur} />}
        </>
      );
  }
};

export const SelectList = function SelectList({
  className,
  style,
  type,
  icon,
  iconName,
  logout,
  onLogoutPress,
  ...props
}: SelectListProps) {
  const state = useListState(props);

  return (
    <SelectListBox
      {...props}
      state={state}
      type={type}
      iconName={iconName}
      icon={icon}
      logout={logout}
      onLogoutPress={onLogoutPress}
    />
  );
};

export default SelectListBox;
