import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { useVerifyDocument } from '@/api';
import { requiredFileSchema } from '@/utils/validation';
import { DocumentType } from '@/api/common.types';
import { showNotification } from '@/utils/showNotification';
import { useState } from 'react';

interface FormData {
  document: File[];
}

export const useSubmitVerificationDocument = (type: DocumentType, onSuccessCb: () => void) => {
  const { mutate } = useVerifyDocument({
    onSuccess: () => onSuccessCb(),
    onError: () => showNotification('Error. Try again later'),
    onSettled: () => setIsSubmitting(false),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(requiredFileSchema),
    defaultValues: { document: [] },
  });

  const onSubmit: SubmitHandler<FormData> = (data) => {
    setIsSubmitting(true);
    data.document.forEach((file) => {
      mutate({ type, file });
    });
  };

  return { control, handleSubmit, isDirty, isValid, isSubmitting, reset, onSubmit };
};
