import {
  useQuery,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  UseQueryResult,
  UseQueryOptions,
} from 'react-query';
import { url, axiosClient } from '@/api';
import { AxiosError } from 'axios';
import { DocumentType, ErrorObject, OnfidoStatus, OnfidoStep } from '../common.types';
import { DEVICE_TOKEN } from '@/constants/storage';

export interface UserCredentials {
  email: string;
  password: string;
  deviceToken?: string;
}

export interface SignInStep2Payload {
  rememberMe: boolean;
  code: string;
}

export interface SignInStep1Response {
  accessToken?: string;
  isNeedMfa?: boolean;
  emailVerified: boolean;
  isPersonal: boolean;
  message: string;
  mfaType?: MfaType;
  mobile?: string;
  mobileVerified: boolean;
}

export interface VerificationResponse {
  success: boolean;
  message: string;
  verified: boolean;
  accessToken: string;
}

export enum MfaType {
  NONE = 'NONE',
  REGISTRATION = 'REGISTRATION',
  SMS = 'SMS',
  QR = 'QR',
}

export enum UserTypeBE {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
}

export interface AuthUserResponse {
  websocketId?: string;
  emailVerified?: boolean;
  mobileVerified?: boolean;
  notificationAllowed?: boolean;
  finalStepPassed?: boolean;
  mfaType?: MfaType;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  picture?: string;
  referralCode?: string;
  email?: string;
  mobile?: string;
  lastPasswordChangeDate?: Date;
  userType?: UserTypeBE;
  entityName?: string;
  createdAt?: Date;
  onfidoStep?: OnfidoStep;
  onfidoStatus?: OnfidoStatus;
  termsAndConditionsAgreed?: boolean;
}

export type VerifyEmailQueryParameters = {
  email: string;
  code: string;
};

interface ChangePasswordUnauthorizedUserPayload {
  email: string;
  code: string;
}

interface ChangePasswordPayload {
  smsCode?: string;
  newPassword?: string;
}

export interface IVerifyDocumentRequest {
  type: DocumentType;
  file: File;
}

export interface SignUpUserPayload {
  email: string;
  password: string;
  referralCode?: string;
  userType: UserTypeBE;
}

interface VerificationPayload {
  verificationCode: string;
  isLogin?: boolean;
}

export interface GenerateQrResponse {
  qrCode: string;
  secret: string;
}

export interface ActivateQrResponse {
  message: string;
  success: boolean;
}

interface useRecallVoiceOTPResponse {
  accessToken: string | null;
  message: string | null;
  success: boolean;
  verified: boolean;
}

export interface VerifyCodeResponse {
  type: 'REGISTRATION' | 'SMS' | 'QR' | 'NONE' | undefined;
}

export function useSignInStep1<TContext>(
  options?: Omit<
    UseMutationOptions<SignInStep1Response, AxiosError<ErrorObject>, UserCredentials, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<SignInStep1Response, AxiosError<ErrorObject>, UserCredentials, TContext> {
  const key = ['Client', 'SignInStep1'];

  const storedDeviceToken = localStorage.getItem(DEVICE_TOKEN);

  return useMutation(
    async (body: UserCredentials) =>
      await axiosClient.post(url.authController.logInStep1, {
        ...body,
        ...(storedDeviceToken && { deviceToken: storedDeviceToken }),
      }),
    {
      ...options,
      mutationKey: key,
    },
  );
}

export function useSignInStep2<TContext>(
  options?: Omit<
    UseMutationOptions<SignInStep1Response, AxiosError<ErrorObject>, SignInStep2Payload, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<SignInStep1Response, AxiosError<ErrorObject>, SignInStep2Payload, TContext> {
  const key = ['Client', 'SignInStep2'];

  return useMutation(async (body: SignInStep2Payload) => await axiosClient.post(url.authController.logInStep2, body), {
    ...options,
    mutationKey: key,
  });
}

export function useSignUp<TContext>(
  options?: Omit<
    UseMutationOptions<void, AxiosError<ErrorObject>, SignUpUserPayload, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<void, AxiosError<ErrorObject>, SignUpUserPayload, TContext> {
  const key = ['SignUpBusiness'];

  return useMutation((body: SignUpUserPayload) => axiosClient.post(url.authController.createUser, body), {
    ...options,
    mutationKey: key,
  });
}

export function useInitVoiceOTP<TContext>(
  options?: Omit<
    UseMutationOptions<VerificationResponse, AxiosError<ErrorObject>, string, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<VerificationResponse, AxiosError<ErrorObject>, string, TContext> {
  const key = ['InitVoiceOTP'];

  return useMutation((mobilePhone: string) => axiosClient.post(url.authController.initVoiceOTP, { mobilePhone }), {
    ...options,
    mutationKey: key,
  });
}

export function useRecallVoiceOTP<TContext>(
  options?: Omit<
    UseMutationOptions<useRecallVoiceOTPResponse, AxiosError<ErrorObject>, string, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<useRecallVoiceOTPResponse, AxiosError<ErrorObject>, string, TContext> {
  const key = ['VoiceOtpReCall'];

  return useMutation((mobilePhone: string) => axiosClient.post(url.authController.recallVoiceOTP, { mobilePhone }), {
    ...options,
    mutationKey: key,
  });
}

export function useVerifyVoiceOTP<TContext>(
  options?: Omit<
    UseMutationOptions<VerificationResponse, AxiosError<ErrorObject>, VerificationPayload, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<VerificationResponse, AxiosError<ErrorObject>, VerificationPayload, TContext> {
  const key = ['VerifyVoiceOTP'];

  return useMutation(
    (body: VerificationPayload) =>
      axiosClient.post(url.authController.verifyVoiceOTP, {
        verificationCode: body.verificationCode,
        login: body.isLogin,
      }),
    {
      ...options,
      mutationKey: key,
    },
  );
}

export function useGetUserQuery<TSelectData = AuthUserResponse, TError = AxiosError<ErrorObject>>(
  options?: Omit<UseQueryOptions<AuthUserResponse, TError, TSelectData>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TSelectData, TError> {
  return useQuery<AuthUserResponse, TError, TSelectData>(
    ['Client', 'GetUser'],
    () => axiosClient.get(url.authController.getCurrentUser),
    options,
  );
}

export function useVerifyEmailQuery<TSelectData = void, TError = AxiosError<ErrorObject>>(
  options: [
    VerifyEmailQueryParameters,
    Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['Client', 'VerifyEmailKey'],
    () => axiosClient.get(url.authController.verifyEmailCode(options[0].email, options[0].code)),
    options?.[1],
  );
}

export function useResendVerificationEmailMutation<TContext>(
  options?: Omit<UseMutationOptions<void, AxiosError<ErrorObject>, string, TContext>, 'mutationKey' | 'mutationFn'>,
): UseMutationResult<void, AxiosError<ErrorObject>, string, TContext> {
  const key = ['Client', 'ResendVerificationEmailCode'];
  return useMutation(async (email: string) => await axiosClient.post(url.authController.resendVerifyEmailCode(email)), {
    ...options,
    mutationKey: key,
  });
}

export function useResendCodeForPasswordResetForUnauthorized<TContext>(
  options?: Omit<UseMutationOptions<void, AxiosError<ErrorObject>, string, TContext>, 'mutationKey' | 'mutationFn'>,
): UseMutationResult<void, AxiosError<ErrorObject>, string, TContext> {
  const key = ['Client', 'ResendCodeForPasswordResetForUnauthorized'];

  return useMutation(
    async (email: string) => await axiosClient.post(url.authController.resendResetPasswordVerificationEmailCode(email)),
    { ...options, mutationKey: key },
  );
}

export function useVerifyCodeForUnauthorizedUser<TContext>(
  options?: Omit<
    UseMutationOptions<VerifyCodeResponse, AxiosError<ErrorObject>, ChangePasswordUnauthorizedUserPayload, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<VerifyCodeResponse, AxiosError<ErrorObject>, ChangePasswordUnauthorizedUserPayload, TContext> {
  const key = ['Client', 'VerifyCodeForUnauthorizedUser'];
  return useMutation(
    async (body: ChangePasswordUnauthorizedUserPayload) =>
      await axiosClient.post(url.authController.verifyCodeForUnauthorizedUser(body.email, body.code)),
    { ...options, mutationKey: key },
  );
}

export function useResetPasswordForUnauthorizedUser<TContext>(
  options?: Omit<
    UseMutationOptions<
      void,
      AxiosError<ErrorObject>,
      ChangePasswordUnauthorizedUserPayload & ChangePasswordPayload,
      TContext
    >,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<
  void,
  AxiosError<ErrorObject>,
  ChangePasswordUnauthorizedUserPayload & ChangePasswordPayload,
  TContext
> {
  const key = ['Client', 'ResetPasswordForUnauthorizedUser'];
  return useMutation(
    async (body: ChangePasswordUnauthorizedUserPayload & ChangePasswordPayload) =>
      await axiosClient.patch(url.authController.verifyCodeForUnauthorizedUser(body.email, body.code), {
        smsCode: body.smsCode,
        newPassword: body.newPassword,
      }),
    { ...options, mutationKey: key },
  );
}

export function useChangePasswordForAuthorizedUser<TContext>(
  options?: Omit<
    UseMutationOptions<void, AxiosError<ErrorObject>, ChangePasswordPayload, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<void, AxiosError<ErrorObject>, ChangePasswordPayload, TContext> {
  const key = ['Client', 'ChangePasswordForAuthorizedUser'];

  return useMutation(
    async (body: ChangePasswordPayload) =>
      await axiosClient.post(url.authController.changePasswordForAuthorizedUser, body),
    { ...options, mutationKey: key },
  );
}

export function useSendVerificationCodeForAuthorisedUser<TSelectData = void, TError = AxiosError<ErrorObject>>(
  options?: Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['Client', 'SendVerificationCodeForAuthorisedUser'],
    () => axiosClient.get(url.authController.sendVerificationCodeToAuthorisedUser),
    options,
  );
}

export function useVerifyDocument<TContext>(
  options?: Omit<
    UseMutationOptions<void, AxiosError<ErrorObject>, IVerifyDocumentRequest, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<void, AxiosError<ErrorObject>, IVerifyDocumentRequest, TContext> {
  const key = ['VerifyDocument'];

  return useMutation(
    async (opts: IVerifyDocumentRequest) => {
      const formData = new FormData();
      formData.set('file', opts.file as File);
      return await axiosClient.post(url.authController.verifyDocument(opts.type), formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    { ...options, mutationKey: key },
  );
}

export function useSetMerchantUrl<TContext>(
  options?: Omit<UseMutationOptions<void, AxiosError<ErrorObject>, string, TContext>, 'mutationKey' | 'mutationFn'>,
): UseMutationResult<void, AxiosError<ErrorObject>, string, TContext> {
  const key = ['SetMerchantUrl'];

  return useMutation(
    async (preferredUrl: string) => {
      return await axiosClient.post(url.authController.setMerchantUrl(preferredUrl));
    },
    { ...options, mutationKey: key },
  );
}

export function useCheckMfa<TSelectData = { type: MfaType }, TError = AxiosError<ErrorObject>>(
  options?: Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['Client', 'CheckMfa'],
    () => axiosClient.get(url.authController.checkMfa),
    options,
  );
}

export function useSendMfaSms<TContext>(
  options?: Omit<UseMutationOptions<void, AxiosError<ErrorObject>, void, TContext>, 'mutationKey' | 'mutationFn'>,
): UseMutationResult<void, AxiosError<ErrorObject>, void, TContext> {
  const key = ['SendMfaSms'];

  return useMutation(
    async () => {
      return await axiosClient.post(url.authController.sendMfaSms);
    },
    { ...options, mutationKey: key },
  );
}

export function useGenerateQr<TContext>(
  options?: Omit<
    UseMutationOptions<GenerateQrResponse, AxiosError<ErrorObject>, void, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<GenerateQrResponse, AxiosError<ErrorObject>, void, TContext> {
  const key = ['GenerateQr'];

  return useMutation(
    async () => {
      return await axiosClient.post(url.authController.generateQr);
    },
    { ...options, mutationKey: key },
  );
}

export function useActivateQr<TContext>(
  options?: Omit<
    UseMutationOptions<ActivateQrResponse, AxiosError<ErrorObject>, string, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<ActivateQrResponse, AxiosError<ErrorObject>, string, TContext> {
  const key = ['ActivateQr'];

  return useMutation(
    async (code: string) => {
      return await axiosClient.post(url.authController.activateQr, { code });
    },
    { ...options, mutationKey: key },
  );
}
