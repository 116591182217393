import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { allowOnlyNumber } from '@/utils/functions';
import { profileLocationSchema } from '@/utils/validation';
import map from 'lodash/map';
import toPairs from 'lodash/toPairs';
import { showNotification } from '@/utils/showNotification';
import { AddressPayload, useGetAddress, useUpdateAddress } from '@/api/queryHooks/useUserController';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useIsVerified } from '@/hooks/useIsVerified';
import clsx from 'clsx';
import { Button } from '../../buttons';
import { Input } from '../Input';
import { GooglePlaces, SelectedAddress } from '../GooglePlaces';
import styles from './Location.module.css';

interface LocationProps {
  isPopup?: boolean;
  onSuccess?: () => void;
  buttonText?: string;
}
interface FormData extends SelectedAddress {
  fullAddress: string;
}

type AddressKey = keyof (SelectedAddress & AddressPayload);

export const Location = ({ isPopup, onSuccess, buttonText = 'Update Location' }: LocationProps) => {
  const { isDesktop } = useMediaQuery();
  const isVerified = useIsVerified();
  const { refetch: refetchAddress } = useGetAddress({
    refetchOnMount: true,
    onSuccess(address) {
      setAddressValues(address);
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { isValid },
  } = useForm<FormData>({
    resolver: zodResolver(profileLocationSchema),
    defaultValues: {
      fullAddress: '',
      countryIso: '',
      country: '',
      state: '',
      town: '',
      street: '',
      postcode: '',
      unitNumber: '',
      buildingNumber: '',
    },
  });

  const { mutate: updateAddress, isLoading: isUpdateAddressLoading } = useUpdateAddress({
    onSuccess() {
      onSuccess?.();
      refetchAddress();
      showNotification('Address updated successfully', 'success');
    },
  });

  function setAddressValues(addressObj: SelectedAddress | AddressPayload) {
    const addressPairs = toPairs(addressObj);
    map(addressPairs, ([key, value]) => {
      setValue(key as AddressKey, value);
    });
    trigger();
  }

  function onGooglePlacesSuccess(selectedAddress: SelectedAddress) {
    setAddressValues(selectedAddress);
  }

  function onGooglePlacesError(error: string) {
    showNotification(error);
  }

  function onSubmit({ countryIso, ...data }: FormData) {
    updateAddress({ ...data, country: countryIso });
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="fullAddress"
        control={control}
        render={({ field }) => (
          <GooglePlaces
            {...field}
            className={styles.fullWidthElement}
            size={isDesktop ? 'large' : 'medium'}
            placeholder="Full Address"
            label="Full Address"
            onSuccess={onGooglePlacesSuccess}
            onError={onGooglePlacesError}
            isReadOnly={isVerified}
          />
        )}
      />
      <Controller
        name="country"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            isReadOnly={isVerified}
            className={styles.standardWidthElement}
            size={isDesktop ? 'large' : 'medium'}
            placeholder="Country"
            label="Country"
          />
        )}
      />
      <Controller
        name="state"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            isReadOnly={isVerified}
            className={styles.standardWidthElement}
            size={isDesktop ? 'large' : 'medium'}
            placeholder="State"
            label="State"
          />
        )}
      />
      <Controller
        name="town"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            isReadOnly={isVerified}
            className={styles.standardWidthElement}
            size={isDesktop ? 'large' : 'medium'}
            placeholder="Suburb"
            label="Suburb"
          />
        )}
      />
      <Controller
        name="street"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            isReadOnly={isVerified}
            className={styles.halfWidthElement}
            size={isDesktop ? 'large' : 'medium'}
            placeholder="Street"
            label="Street"
          />
        )}
      />
      <Controller
        name="postcode"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            isReadOnly={isVerified}
            onChange={(text) => onChange(allowOnlyNumber(text))}
            value={value}
            className={styles.halfWidthElement}
            size={isDesktop ? 'large' : 'medium'}
            placeholder="Postcode"
            label="Postcode"
          />
        )}
      />
      <Controller
        name="unitNumber"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            isReadOnly={isVerified}
            className={styles.halfWidthElement}
            size={isDesktop ? 'large' : 'medium'}
            placeholder="Unit Number"
            label="Unit Number"
          />
        )}
      />
      <Controller
        name="buildingNumber"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            isReadOnly={isVerified}
            className={styles.halfWidthElement}
            size={isDesktop ? 'large' : 'medium'}
            placeholder="Building Number"
            label="Building Number"
          />
        )}
      />
      <Button
        className={clsx(styles.fullWidthElement, styles.button)}
        size={isDesktop ? 'large' : 'medium'}
        type="submit"
        isDisabled={!isValid || isVerified}
        loading={isUpdateAddressLoading}
        fluid={isPopup && !isDesktop}
      >
        {buttonText}
      </Button>
    </form>
  );
};
