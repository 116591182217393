import { useLocalStorage } from 'usehooks-ts';
import { ACCESS_TOKEN, USER_DETAILS } from '@/constants/storage';
import { useRouter } from 'next/router';
import { Routes } from '@/constants/routes';
import { useWebsiteRedirect } from '@/store/websiteRedirect';
import { shallow } from 'zustand/shallow';

export const useLogOut = (withRedirect: boolean = true) => {
  const websiteRedirect = useWebsiteRedirect((state) => state, shallow);

  const router = useRouter();
  const [, setToken] = useLocalStorage(ACCESS_TOKEN, null);
  const [, setUser] = useLocalStorage(USER_DETAILS, null);

  function logOut() {
    if (withRedirect) {
      router.push(Routes.SIGN_IN);
    }

    websiteRedirect.reset();
    setToken(null);
    setUser(null);
  }

  return logOut;
};
