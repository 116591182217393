import { useCopyToClipboard } from 'usehooks-ts';
import { useGetHostedUrl } from '@/api/queryHooks/useMerchantCryptoController';
import clsx from 'clsx';
import { SUPPORT_EMAIL } from '@/constants/common';
import { Input } from '../../form/Input';
import styles from './InvoiceToHostedPage.module.css';
import { Button } from '../../buttons';
import { Spinner } from '../../Spinner';

export const InvoiceToHostedPage = () => {
  const [, copy] = useCopyToClipboard();
  const { data, isLoading } = useGetHostedUrl({ refetchOnMount: true });
  const hasUrl = data?.hasUrl;

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Start Accepting Now</h3>
      {!isLoading && (
        <>
          <p className={clsx(styles.description, { [styles.descriptionNoMargin]: !hasUrl })}>
            {hasUrl
              ? 'Send this link to clients to start accepting cryptocurrency as a payment option. Note that settlement will come in the form of your nominated FIAT currency'
              : 'Contact support to start accepting cryptocurrency payments. It is as easy as uploading a logo and a description!'}
          </p>
          {hasUrl && (
            <Input
              copyButton
              defaultValue={data?.url.replace('https://', '')}
              isReadOnly
              inputClassName={styles.input}
              inputFieldClassName={styles.inputField}
              size="large"
              onButtonPress={() => copy(data?.url.replace('https://', '') ?? '')}
              label="Hosted Page Link"
            />
          )}
          {!hasUrl && (
            <Button className={styles.contactButton} href={SUPPORT_EMAIL} target="_blank" rel="noreferrer noopener">
              Contact Support
            </Button>
          )}
        </>
      )}
      {isLoading && <Spinner />}
    </div>
  );
};
