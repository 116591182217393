import clsx from 'clsx';
import React from 'react';
import { useDropzone, FileWithPath, FileRejection } from 'react-dropzone';
import { truncateString, formatSize } from '@/utils/functions';
import { showNotification } from '@/utils/showNotification';
import { Icon } from '../../Icon';
import styles from './DragAndDrop.module.css';

interface DragAndDropProps {
  className?: string;
  multiple?: boolean;
  onChange: (files: File[]) => void;
  value: File[];
}

const MAX_SIZE = 10485760;

const showMaxSizeError = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
  if (rejectedFiles && rejectedFiles.length > 0) {
    rejectedFiles.forEach((file) => {
      if (file.file.size > MAX_SIZE) showNotification('The file uploaded exceeds 10MB', 'error');
    });
  }
};

export const DragAndDrop = ({ className, multiple, onChange, value }: DragAndDropProps) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple,
    maxSize: MAX_SIZE,
    onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      showMaxSizeError(acceptedFiles, rejectedFiles);
      return multiple ? onChange([...value, ...acceptedFiles]) : onChange(acceptedFiles);
    },
  });

  const files = () => {
    const filesList = multiple ? value : acceptedFiles;

    return filesList.map((file: FileWithPath) => (
      <li key={file.path}>
        {truncateString({ string: file.path })} - {formatSize(file.size)}
      </li>
    ));
  };

  return (
    <section className={clsx(styles.container, className)}>
      <div className={styles.dropzone} {...getRootProps()}>
        <input {...getInputProps()} />
        <Icon name="upload" size={36} className={styles.icon} />
        {files().length === 0 ? (
          <p className={styles.title}>
            <span className={styles.primary}>Choose File{multiple ? 's' : ''}</span> or{' '}
            <span className={styles.neutral}>Drag Here</span>
          </p>
        ) : (
          <ol className={clsx(styles.title, styles.files, { [styles.filesMultiple]: files().length > 1 })}>
            {files()}
          </ol>
        )}
        <p className={styles.description}>Size limit: 10MB</p>
      </div>
    </section>
  );
};
