import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { axiosClient, url } from '@/api';
import { AxiosError } from 'axios';
import { CryptoCurrency, ErrorObject } from '../common.types';

export enum ProcessableType {
  PROCESSABLE = 'PROCESSABLE',
  UNPROCESSABLE = 'UNPROCESSABLE',
}

export interface CryptoCoinsResponse {
  currency: CryptoCurrency;
  displayName: string;
}

export interface CoinNetworksResponse {
  cryptoCurrency: string;
  cryptoNetwork: string;
  description: string;
  explorerUrl?: string;
  memoRegex?: string | null;
  walletRegex?: string | null;
  networkFee?: number | string;
  contractAddress: string | null;
  chainId?: string | null;
  rpcUrl?: string | null;
}

export function useGetCryptoCoins<TSelectData = CryptoCoinsResponse[], TError = AxiosError<ErrorObject>>(
  options?: Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['GetCryptoCoins'],
    () => axiosClient.get(url.commonCryptoController.getCryptoCoins),
    options,
  );
}

export function useGetCoinNetworks<TSelectData = CoinNetworksResponse[], TError = AxiosError<ErrorObject>>(
  options: [
    { cryptoCurrency: string },
    Omit<UseQueryOptions<TSelectData, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  return useQuery<TSelectData, TError, TSelectData>(
    ['GetCoinNetworks', options[0].cryptoCurrency],
    () => axiosClient.get(url.commonCryptoController.getCoinNetworks(options[0].cryptoCurrency)),
    options[1],
  );
}
