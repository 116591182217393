import { useRef } from 'react';
import clsx from 'clsx';
import styles from './Alert.module.css';

export interface AlertProps {
  alertType: 'error' | 'warn' | 'success';
  alertBody: string;
  size?: 'medium' | 'small';
}

export const Alert = ({
  size = 'medium',
  alertType = 'error',
  alertBody,
}: AlertProps) => {
  const ref = useRef(null);

  return (
    <div
      className={clsx(styles.alert, styles[alertType], styles[size])}
      ref={ref}
    >
      <span
        className={clsx(styles.alertType, {
          [styles.smallAlertType]: size === 'small',
        })}
      >
        {alertType}
      </span>
      <p
        className={clsx(styles.alertBody, {
          [styles.smallAlertBody]: size === 'small',
        })}
      >
        {alertBody}
      </p>
    </div>
  );
};
