export const SUPPORTED_COUNTRIES = [
  'AU', // Australia
  'AT', // Austria
  'BH', // Bahrain
  'BE', // Belgium
  'CA', // Canada
  'DK', // Denmark
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HK', // Hong Kong
  'IE', // Ireland
  'IT', // Italy
  'JP', // Japan
  'LU', // Luxembourg
  'NL', // Netherlands
  'NO', // Norway
  'PL', // Poland
  'PT', // Portugal
  'SG', // Singapore
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
  'CH', // Switzerland
  'US', // United States
];

export const SUPPORTED_SELL_FLOW_COUNTRIES = [
  'AT', // Austria
  'BH', // Bahrain
  'BE', // Belgium
  'CA', // Canada
  'DK', // Denmark
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HK', // Hong Kong
  'IE', // Ireland
  'IT', // Italy
  'JP', // Japan
  'LU', // Luxembourg
  'NL', // Netherlands
  'NO', // Norway
  'NZ', // New Zealand
  'PL', // Poland
  'PT', // Portugal
  'SG', // Singapore
  'SK', // Slovakia
  'SI', // Slovenia
  'ZA', // South Africa
  'ES', // Spain
  'SE', // Sweden
  'CH', // Switzerland
  'GB', // United Kingdom
  'US', // United States
];
