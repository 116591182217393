import React from 'react';
import { toast } from 'react-toastify';
import { Icon } from '@/components/common/Icon';
import { ToastSuccessIcon } from '@/components/common/IconButton';
import { SUPPORT_EMAIL } from '@/constants/common';

export function showNotification(
  message: React.ReactNode | string | undefined = undefined,
  type: 'success' | 'error' = 'error',
) {
  const options = {
    position: toast.POSITION.TOP_RIGHT,
    closeButton: false,
    className: `${type}-toast-message`,
    hideProgressBar: true,
    icon: type === 'success' ? <ToastSuccessIcon /> : <Icon name="warning" size={22} fill="#F26969" />,
  };

  toast[type](
    message ?? (
      <>
        Error.{' '}
        <a target="_blank" rel="noreferrer noopener" href={SUPPORT_EMAIL}>
          Contact support - support@relaypay.io
        </a>
      </>
    ),
    options,
  );
}
