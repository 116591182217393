export enum PaymentStatusType {
  Empty = 'Empty',
  Locked = 'Locked',
  Processing = 'Processing',
  Expired = 'Expired',
  TxNotAllowed = 'TxNotAllowed',
  PaymentRefunded = 'PaymentRefunded',
  Replaced = 'Replaced',
  Completed = 'Completed',
}

export enum PaymentReplacementReasonTypes {
  UnderPaid = 'UNDER_PAID',
  None = 'NONE',
}
