import clsx from 'clsx';
import React, { createContext, ReactNode, useContext, useRef } from 'react';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { AriaRadioProps, AriaRadioGroupProps } from '@react-types/radio';
import { useRadio, useRadioGroup } from '@react-aria/radio';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { useRadioGroupState, RadioGroupState } from '@react-stately/radio';
import { onEnterPressRadio } from '../../../../utils/events';
import styles from './Radio.module.css';
import { Icon, IconType } from '../../Icon';

export const RadioContext = createContext<RadioGroupState>({
  name: '',
  validationState: 'valid',
  isDisabled: false,
  isReadOnly: false,
  lastFocusedValue: null,
  selectedValue: null,
  setLastFocusedValue: () => undefined,
  setSelectedValue: () => undefined,
});

export interface RadioGroupProps extends AriaRadioGroupProps {
  className?: string;
  ariaLabel?: string;
  size?: 'medium' | 'large';
  children?: ReactNode;
}

export const RadioGroup = ({ className, ariaLabel, ...props }: RadioGroupProps) => {
  const { children } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps } = useRadioGroup({ ...props, 'aria-label': ariaLabel }, state);

  return (
    <div {...radioGroupProps} className={clsx(styles.group, className)}>
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
    </div>
  );
};

export interface RadioChildrenProps {
  isFocused: boolean;
  isChecked: boolean;
}

export interface RadioProps extends AriaRadioProps {
  className?: string;
  contentClassName?: string;
  size?: 'medium' | 'large';
  name?: string;
  nameShort?: string;
  balanceValue?: string | null;
  balanceCurrency?: string | null;
  currencyEqual?: string | null;
  icon?: IconType;
  iconSize?: number;
  iconName?: string;
  checked?: boolean;
}

export const Radio = ({
  className,
  contentClassName,
  size = 'medium',
  children,
  icon,
  iconSize,
  iconName,
  checked,
  ...props
}: RadioProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const state = useContext(RadioContext);
  const { inputProps, isDisabled } = useRadio(
    {
      ...props,
      onKeyUp: onEnterPressRadio(props, state, props.value),
    },
    state,
    ref,
  );
  const { focusProps, isFocusVisible: isFocused } = useFocusRing();
  const isChecked = !!inputProps.checked;
  return (
    <label
      className={clsx(styles.wrapper, className, {
        [styles.disabledWrapper]: isDisabled,
      })}
    >
      <VisuallyHidden>
        <input
          {...mergeProps(inputProps, focusProps)}
          className={styles.input}
          checked={checked}
          ref={ref}
          type="radio"
        />
      </VisuallyHidden>
      <div className={styles.radioInner}>
        {icon && (
          <div className={styles.iconWrapper}>
            <Icon name={icon} className={styles.icon} size={iconSize} />
            {iconName && <span className={styles.iconName}>{icon}</span>}
          </div>
        )}
        {children && <span className={clsx(styles.label, contentClassName)}>{children}</span>}
      </div>
      <div
        className={clsx(styles.radio, {
          [styles.radioLarge]: size === 'large',
          [styles.checked]: isChecked,
          [styles.focused]: isFocused,
          [styles.disabledRadio]: isDisabled,
        })}
      >
        {isChecked && (
          <span
            className={clsx(styles.radioDot, {
              [styles.radioDotLarge]: size === 'large',
              [styles.checkedRadioDot]: isChecked,
              [styles.focused]: isFocused,
              [styles.disabledDot]: isDisabled,
            })}
          />
        )}
      </div>
    </label>
  );
};

export default Radio;
