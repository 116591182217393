import clsx from 'clsx';
import React, { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import SelectComponent, { SingleValue, GroupBase, ClassNamesConfig, StylesConfig } from 'react-select';
import { useBoolean, useWindowSize } from 'usehooks-ts';
import { div } from '@tensorflow/tfjs-core';
import { currencyCodesWithFlags } from '@/components/common/form/SelectCurrencyCountry/useSelectedCurrency';
import { isNumericValue } from '@/utils/functions';
import { ItemType, SelectRCOption } from './SelectRCOption';
import { Icon } from '../../Icon';
import styles from './SelectRC.module.css';

export type ItemTypeSelect = SingleValue<ItemType>;

export interface SelectRCProps {
  children?: string;
  onChange?: (v: ItemTypeSelect) => void;
  options: ItemType[];
  value?: ItemTypeSelect;
  id?: string | number;
  width?: number;
  size?: 'medium' | 'large' | 'small';
  isDisabled?: boolean;
  isHosted?: boolean;
  isBuy?: boolean;
  isCurrency?: boolean;
  isOverview?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
  className?: string;
  withoutRadios?: boolean;
  closeMenuOnSelect?: boolean;
  variant?: 'phone' | 'phoneMedium' | 'cryptoCurrency' | 'wallet' | 'cryptoCurrencyMedium' | 'filter';
  filterSelect?: boolean;
  valueClassName?: string;
  hasValue?: boolean;
  menuClassName?: string;
  menuPosition?: 'fixed' | 'absolute';
  blurMenuEnabled?: boolean;
  themeColor?: string;
  borderRadius?: string;
}

export const SelectRC: FC<PropsWithChildren<SelectRCProps>> = ({
  children,
  onChange,
  options,
  value,
  id,
  width,
  isSearchable = true,
  size,
  isDisabled,
  isHosted,
  isBuy,
  isOverview,
  isCurrency,
  placeholder,
  className,
  withoutRadios,
  closeMenuOnSelect,
  variant,
  valueClassName,
  filterSelect,
  hasValue,
  menuClassName,
  menuPosition = 'absolute',
  blurMenuEnabled = true,
  borderRadius,
  themeColor,
}) => {
  const { width: screenWidth } = useWindowSize();
  const menuIsOpen = useBoolean(false);
  const isLarge = useMemo(() => size === 'large', [size]);
  const [prevInputValue, setPrevInputValue] = useState('');

  const selectStyles: StylesConfig<ItemType> = {
    valueContainer: (provided) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row-reverse',
    }),
  };

  const flag = currencyCodesWithFlags.find((el) => el.currency === value?.label)?.flag;

  const customStyles: StylesConfig<ItemType, false, GroupBase<ItemType>> = {
    control: (provided, { isFocused }) => ({
      ...provided,
      borderColor: themeColor && (isFocused || hasValue) ? `${themeColor} !important` : '',
      borderRadius: borderRadius ? `${borderRadius}px !important` : '',
    }),
  };

  const classNames: ClassNamesConfig<ItemType, false, GroupBase<ItemType>> = {
    control: ({ isFocused }) =>
      clsx(styles.input, {
        [styles.large]: isLarge,
        [styles.small]: size === 'small',
        [styles.focused]: isFocused || hasValue,
        [styles.overview]: isOverview,
        [styles.hosted]: isHosted,
        [styles.currency]: isCurrency,
        [styles.overviewSmall]: isOverview && size === 'small',
        [styles.hostedMedium]: isHosted && size === 'medium',
        [styles.filterSelect]: filterSelect,
      }),
    placeholder: () => clsx(styles.placeholder, { [styles.large]: isLarge }, { [styles.filterSelect]: filterSelect }),
    menu: () =>
      clsx(
        styles.menu,
        { [styles.filterMenu]: filterSelect },
        { [styles.blurMenu]: options.length > 6 && blurMenuEnabled },
      ),
    menuList: () => clsx(styles.menuList, menuClassName && menuClassName),
    singleValue: () =>
      clsx(styles.value, valueClassName, {
        [styles.emptyValue]: value?.value === '',
        [styles.emptyValueMedium]: size === 'medium',
        [styles.emptyValueSmall]: size === 'small',
        [styles.hostedValue]: isHosted,
        [styles.buyValue]: isBuy,
      }),
    option: () => styles.option,
    container: () => clsx(styles.container, { [styles.filterContainer]: filterSelect }),
  };

  const SingleValue = (props: any) => {
    return (
      <div className={styles.row}>
        <div className={styles.flag}>{isCurrency && flag}</div>
        {props.children}
      </div>
    );
  };

  const renderDropdownIndicator = useCallback(() => {
    return (
      <Icon
        name="chevronDown"
        size={screenWidth > 1000 ? 20 : 18}
        className={clsx(styles.dropdownIndicator, {
          [styles.active]: menuIsOpen.value,
        })}
      />
    );
  }, [menuIsOpen.value, screenWidth]);

  const onInputChangeHandler = (inputValue: string) => {
    if (!isNumericValue(inputValue)) {
      setPrevInputValue(inputValue);
      return inputValue;
    }
    return prevInputValue;
  };

  return (
    <div className={clsx(styles.select, className, variant && styles[variant])} style={{ width }}>
      {!!children && (
        <label
          className={clsx(styles.label, {
            [styles.labelDisabled]: isDisabled,
            [styles.labelLarge]: isLarge,
          })}
        >
          {children}
        </label>
      )}
      <SelectComponent
        instanceId={id}
        value={value || null}
        isSearchable={isSearchable}
        options={options}
        onInputChange={onInputChangeHandler}
        onChange={(v) => onChange && onChange(v)}
        menuIsOpen={menuIsOpen.value}
        onMenuOpen={menuIsOpen.setTrue}
        onMenuClose={menuIsOpen.setFalse}
        menuPosition={menuPosition}
        components={{
          Option: (props) => (
            <SelectRCOption {...props} withoutRadios={withoutRadios}>
              {props.children}
            </SelectRCOption>
          ),
          ...(isCurrency && { SingleValue }),
          IndicatorSeparator: undefined,
          DropdownIndicator: renderDropdownIndicator,
        }}
        closeMenuOnSelect={closeMenuOnSelect}
        classNames={classNames}
        placeholder={placeholder}
        styles={customStyles}
        {...(isCurrency && { styles: selectStyles })}
      />
    </div>
  );
};

export default SelectRC;
