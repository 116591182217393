import styles from './Chips.module.css';
import { CloseButton } from '../buttons';

export interface ChipsProps {
  range: string;
  currency: string;
}

export const Chips = ({ range, currency }: ChipsProps) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>Price Range:</span>
      <span className={styles.range}>{range}</span>
      <span className={styles.currency}>{currency}</span>
      <CloseButton
        size={20}
        className={styles.button}
        iconClassName={styles.icon}
        onPress={() => {}}
      />
    </div>
  );
};
