import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { url, axiosClient } from '@/api';
import { AxiosError } from 'axios';
import { CryptoCurrency, CryptoNetwork, ErrorObject, FiatCurrency, PaymentType } from '../common.types';
import { CryptoFrontendStatus } from '@/api/queryHooks/useTransactionsController';

export enum MerchantTransactionType {
  HOSTED = 'HOSTED',
  NATIVE = 'NATIVE',
}

interface CreateMerchantTxnResponse {
  merchantId?: string;
  orderId?: string;
  accountId?: string;
  surname?: string;
  email?: string;
  fiatCurrency?: FiatCurrency;
  cryptoCurrency?: CryptoCurrency;
  cryptoNetwork?: CryptoCurrency;
  cryptoAmount?: number;
  fiatAmount?: number;
  merchantTransactionType: MerchantTransactionType;
  payoutType?: PaymentType;
  ecommerceTxRequestId?: string;
  xeroShortCode?: string;
}

interface MerchantTxnResponse
  extends Pick<CreateMerchantTxnResponse, 'email' | 'payoutType' | 'merchantId' | 'orderId' | 'cryptoNetwork'> {
  id?: string;
  blockchainTxHash?: string;
  created: string;
  updated?: string;
  frontendStatus: CryptoFrontendStatus;
}

export interface CreateMerchantTxn {
  cryptoCurrency: CryptoCurrency;
  email: string;
  fiatAmount: number;
  orderId: string;
  merchantId: string;
  merchantIndependentReserve?: boolean;
  merchant: string;
  sourceAddress?: string;
  fiatCurrency?: FiatCurrency;
  merchantTransactionType?: 'NATIVE';
  payoutType?: 'COMMERCE';
  ecommerce?: boolean;
  ecommerceTxRequestId?: string;
  cryptoAmount?: number;
  accountId?: string;
  surname?: string;
  cryptoNetwork?: CryptoCurrency;
}

export interface UpdateMerchantTxn {
  transactionId: string;
  sourceAddress: string;
  blockchainHash: string;
}

export function useCreateMerchantTxn<TContext>(
  options?: Omit<
    UseMutationOptions<MerchantTxnResponse, AxiosError<ErrorObject>, CreateMerchantTxn, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<MerchantTxnResponse, AxiosError<ErrorObject>, CreateMerchantTxn, TContext> {
  const key = ['CreateMerchantTxn'];

  const defaultBody = {
    fiatCurrency: 'AUD',
    merchantTransactionType: MerchantTransactionType.HOSTED,
    payoutType: PaymentType.COMMERCE,
  };

  return useMutation(
    (body: CreateMerchantTxn) => axiosClient.post(url.merchantCryptoController.createTxn, { ...defaultBody, ...body }),
    {
      ...options,
      mutationKey: key,
    },
  );
}

export function useUpdateMerchantTxn<TContext>(
  options?: Omit<
    UseMutationOptions<MerchantTxnResponse, AxiosError<ErrorObject>, UpdateMerchantTxn, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<MerchantTxnResponse, AxiosError<ErrorObject>, UpdateMerchantTxn, TContext> {
  const key = ['UpdateMerchantTxn'];

  return useMutation((body: UpdateMerchantTxn) => axiosClient.patch(url.merchantCryptoController.createTxn, body), {
    ...options,
    mutationKey: key,
  });
}

export function useGetMerchantTxn<TSelectData = MerchantTxnResponse, TError = AxiosError>(
  options: [{ id?: string }, Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined],
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['GetMerchantTxn', options[0].id],
    () => axiosClient.get(url.merchantCryptoController.singleTxn(options[0]?.id)),
    options[1],
  );
}

export function useCancelMerchantTxn<TContext>(
  options?: Omit<UseMutationOptions<void, AxiosError<ErrorObject>, string, TContext>, 'mutationKey' | 'mutationFn'>,
): UseMutationResult<void, AxiosError<ErrorObject>, string, TContext> {
  const key = ['CancelMerchantTxn'];

  return useMutation((id?: string) => axiosClient.delete(url.merchantCryptoController.singleTxn(id)), {
    ...options,
    mutationKey: key,
  });
}

export function useGetHostedUrl<
  TSelectData = {
    hasUrl: boolean;
    url: string;
  },
  TError = AxiosError,
>(
  options?: Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['GetHostedUrl'],
    () => axiosClient.get(url.merchantCryptoController.getHostedUrl),
    options,
  );
}

export function useCheckCapabilities<
  TSelectData = {
    isNativeMerchant: boolean;
    isHostedMerchant: boolean;
    isMerchant: boolean;
  },
  TError = AxiosError,
>(
  options?: [
    { accessToken?: string },
    Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['CheckCapabilities', options?.[0]?.accessToken],
    () => axiosClient.get(url.merchantCryptoController.checkCapabilities),
    options?.[1],
  );
}
