import clsx from 'clsx';
import React, { useState, ReactNode } from 'react';
import { useWindowSize } from 'usehooks-ts';
import map from 'lodash/map';
import { ItemTypeSelect, SelectRC } from '../SelectRC';
import styles from './SelectRCWallet.module.css';
import { Icon } from '../../Icon';
import { ItemType } from '../SelectRC/SelectRCOption';

function renderOption(label?: string, value?: string, icon?: ReactNode) {
  return (
    <div className={styles.row}>
      <div className={styles.icon}>{icon}</div>
      <span>{label}</span>
    </div>
  );
}

export const manualEntryOption = {
  value: 'manualEntry',
  label: renderOption('Manual Entry', 'manualEntry', <Icon name="edit" size={18} className={styles.editIcon} />),
  isManualEntry: true,
};

export interface SelectRCWalletProps {
  className?: string;
  label?: string;
  onSelectChange?: (v: ItemTypeSelect) => void;
  selectValue?: ItemTypeSelect;
  wallets: ItemType[];
}
export const SelectRCWallet = ({ className, label, onSelectChange, wallets, selectValue }: SelectRCWalletProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const { width } = useWindowSize();

  return (
    <div className={clsx(styles.container, className, { [styles.focused]: isFocused })}>
      {label && <label className={styles.label}>{label}</label>}
      <SelectRC
        valueClassName={styles.selectValue}
        className={styles.select}
        variant="wallet"
        size={width > 1000 ? 'large' : 'medium'}
        isBuy
        withoutRadios
        placeholder="Select Wallet Type"
        value={
          selectValue?.icon
            ? {
                label: renderOption(selectValue?.label as string, selectValue?.value as string, selectValue?.icon),
                value: selectValue?.value as string,
              }
            : selectValue
        }
        onChange={onSelectChange}
        options={[
          ...map(wallets, ({ label, value, icon }) => ({
            value,
            label: renderOption(label as string, value as string, icon),
          })),
          { ...manualEntryOption, isManualEntry: wallets.length > 0 },
        ]}
      />
    </div>
  );
};
