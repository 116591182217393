import clsx from 'clsx';
import { PaymentStatusType } from '@/constants/paymentStatuses';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useSellCrypto } from '@/store/sellCrypto';
import { shallow } from 'zustand/shallow';
import React from 'react';
import { CryptoCurrency } from '@/api/common.types';
import { Icon } from '../Icon';
import { Countdown } from '../Countdown';
import styles from './SellCryptoResult.module.css';

interface SellCryptoResultProps {
  status: PaymentStatusType;
  manualTxStatus?: PaymentStatusType | null;
  onRetry: () => void;
  isUnderPaid?: boolean;
  actionButtons?: React.ReactNode;
  setManualTxStatus?: (status: PaymentStatusType) => void;
}

export const SellCryptoResult = ({
  status,
  onRetry,
  isUnderPaid,
  actionButtons,
  setManualTxStatus,
  manualTxStatus,
}: SellCryptoResultProps) => {
  const { isDesktop } = useMediaQuery();
  const sellState = useSellCrypto((state) => state, shallow);

  const counterDuration = 1500;

  switch (status) {
    case PaymentStatusType.Locked:
    case PaymentStatusType.Expired:
      return (
        <div className={styles.resultWrapper}>
          <div className={styles.resultInner}>
            {isDesktop && (
              <div className={styles.info}>
                <p className={styles.title}>
                  {status === PaymentStatusType.Locked ? 'Your rate is locked.' : 'Your rate had expired.'}
                </p>
                {status === PaymentStatusType.Locked && (
                  <p className={styles.description}>We&apos;re waiting for your payment now. Time remaining:</p>
                )}
              </div>
            )}
            <div className={styles.countdownWrapper}>
              <Countdown
                duration={counterDuration}
                status={manualTxStatus || status}
                onComplete={() => setManualTxStatus!(PaymentStatusType.Expired)}
              />
            </div>
          </div>
          <div className={clsx(styles.resultRow, styles.resultRowWithSeparator)}>{actionButtons}</div>
        </div>
      );
    case PaymentStatusType.Processing:
    case PaymentStatusType.TxNotAllowed:
      return (
        <div className={styles.resultWrapper}>
          <div>
            <div className={styles.resultRow}>
              {isDesktop && <Icon name="warning" size={22} className={styles.warningIcon} />}
              <span className={styles.warning}>
                We&apos;ve seen your transfer on blockchain and processing your crypto payment
              </span>
            </div>
            {isUnderPaid && (
              <div className={clsx(styles.resultRow, styles.underpaidResultRow)}>
                {isDesktop && <Icon name="warning" size={22} className={styles.warningIcon} />}
                <span className={styles.underpaidWarning}>
                  The amount of crypto that was transferred was lower than expected. Rest assured we still obtained the
                  best rate for you. Please expect to see a lower amount of AUD when the transaction is complete.
                </span>
              </div>
            )}
          </div>
        </div>
      );

    case PaymentStatusType.Replaced:
    case PaymentStatusType.Completed:
      return (
        <div className={styles.resultWrapper}>
          <div>
            {isUnderPaid && (
              <div className={clsx(styles.resultRow, styles.underpaidResultRow, styles.underpaidResultRowMoved)}>
                {isDesktop && <Icon name="warning" size={22} className={styles.warningIcon} />}
                <span className={styles.underpaidWarning}>
                  The amount of crypto that was transferred was lower than expected. Rest assured we still obtained the
                  best rate for you. Please expect to see a lower amount of AUD when the transaction is complete.
                </span>
              </div>
            )}

            <div className={styles.resultRow}>
              {isDesktop && <Icon name="check" size={18} className={styles.completedIcon} />}
              <div className={styles.completed}>
                <p>
                  Your payment is completed and we transferred the funds based on your instructions. A receipt of this
                  payment is sent to your email address.
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
};
