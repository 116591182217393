import React, { useRef } from 'react';
import clsx from 'clsx';
import { useButton } from '@react-aria/button';
import { AriaButtonProps } from '@react-types/button';
import styles from './CloseButton.module.css';
import { Icon } from '../../Icon/Icon';

export interface CloseButtonProps
  extends Omit<AriaButtonProps<'button'>, 'children'> {
  className?: string;
  size?: number;
  iconClassName?: string;
}

export const CloseButton = ({
  size = 26,
  className,
  iconClassName,
  ...props
}: CloseButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    { ...props, 'aria-label': props['aria-label'] || 'close' },
    ref,
  );
  return (
    <button
      {...buttonProps}
      ref={ref}
      className={clsx(styles.button, className)}
    >
      <Icon
        size={size}
        className={clsx(styles.icon, iconClassName)}
        name="close"
      />
    </button>
  );
};

export default CloseButton;
