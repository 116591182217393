import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { OptionProps, GroupBase } from 'react-select';
import styles from './SelectRCOption.module.css';

export interface ItemType {
  value: string | number;
  label: string | ReactNode;
  isManualEntry?: boolean;
  icon?: ReactNode;
  rate?: string;
  name?: string;
  subname?: string;
  isUpgrade?: boolean;
  networkFee?: string | number;
}

export const SelectRCOption: React.ComponentType<
  OptionProps<ItemType, false, GroupBase<ItemType>> & {
    withoutRadios?: boolean;
  }
> = ({ innerRef, innerProps, children, isSelected, isFocused, withoutRadios, data }) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={clsx(styles.option, { [styles.focused]: isFocused, [styles.manualEntry]: data.isManualEntry })}
    >
      {children}
      {!withoutRadios && (
        <div className={clsx(styles.radio, { [styles.checked]: isSelected })}>
          {isSelected && (
            <span
              className={clsx(styles.radioDot, {
                [styles.checkedRadioDot]: isSelected,
              })}
            />
          )}
        </div>
      )}
    </div>
  );
};
