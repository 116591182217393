import clsx from 'clsx';
import { useEffect } from 'react';
import { Icon } from '../Icon';
import styles from './CodeCountdownTimer.module.css';

interface CodeCountdownTimerProps {
  className?: string;
  timeLeft: number;
  timerText?: string;
  setTimeLeft: (time: number) => void;
}

export const CodeCountdownTimer = ({
  className,
  timeLeft,
  timerText = 'Resend the code in',
  setTimeLeft,
}: CodeCountdownTimerProps) => {
  const getPadTime = (time: number) => time.toString().padStart(2, '0');
  const minutes = getPadTime(Math.floor(timeLeft / 60));
  const seconds = getPadTime(Math.floor(timeLeft % 60));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(timeLeft >= 1 ? timeLeft - 1 : 0);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [setTimeLeft, timeLeft]);

  return (
    <div className={clsx(styles.wrapper, className)}>
      <span>
        {timerText} {minutes}:{seconds}
      </span>
      <Icon name="loader" size={16} className={clsx(styles.icon, styles.rotating)} />
    </div>
  );
};
