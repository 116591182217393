export enum Routes {
  HOME = '/',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  RESET_PASSWORD = '/reset-password',
  CREATE_PASSWORD = '/create-password',
  BUY_CRYPTO = '/buy-crypto',
  SELL_CRYPTO = '/sell-crypto',
  TRANSACTIONS = '/transactions',
  T_BILL_PAYMENT = '/transactions/bill-payment',
  T_BUY_CRYPTO = '/transactions/buy-crypto',
  T_MERCHANT_PAYMENTS = '/transactions/merchant-payments',
  PROFILE_DETAILS = '/profile',
  EXAMPLE = '/example',
  API_TOKEN = '/api-token',
  OVERVIEW = '/overview',
  NOT_FOUND = '/404',
  ERROR = '/_error',
  COMMERCE = '/commerce',
  REFERRAL = '/r',
  MERCHANT_PAYMENT = '/merchant/payment',
  PLUG_IN_PAYMENT = '/merchant/redirecting',
  MAINTENANCE = '/maintenance',
  WIDGET = '/widget',
  WIDGET_GENERATOR = '/widget-generator',
}

export const PUBLIC_ROUTES = [
  Routes.HOME,
  Routes.SIGN_IN,
  Routes.SIGN_UP,
  Routes.RESET_PASSWORD,
  Routes.CREATE_PASSWORD,
  Routes.COMMERCE,
  Routes.MERCHANT_PAYMENT,
  Routes.PLUG_IN_PAYMENT,
  Routes.REFERRAL,
  Routes.WIDGET,
  Routes.WIDGET_GENERATOR,
];
