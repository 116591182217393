import clsx from 'clsx';
import Image from 'next/image';
import { RefObject, useRef } from 'react';
import { useButton } from 'react-aria';
import { useWindowSize } from 'usehooks-ts';
import { Roles } from '@/constants/roles';
import { Icon } from '../Icon';
import styles from './Account.module.css';

export const ACCOUNT_TESTID = 'account';

export interface AccountProps {
  src?: string;
  placeholder?: string;
  className?: string;
  role: Roles;
  buttonRef?: RefObject<HTMLButtonElement>;
  onClick?: () => void;
}

export const Account = (props: AccountProps) => {
  const { width } = useWindowSize();
  const ref = useRef(null);
  const { src, className, placeholder, role, buttonRef = ref, onClick, ...otherProps } = props;
  const styleProps = {
    style: {
      width: width > 1000 ? '40px' : '36px',
      height: width > 1000 ? '40px' : '36px',
    },
    className: clsx(styles.avatar, className),
  };

  const { buttonProps } = useButton(otherProps, buttonRef);

  return (
    <button
      data-testid={ACCOUNT_TESTID}
      {...styleProps}
      {...buttonProps}
      onClick={onClick}
      className={styles.button}
      ref={buttonRef}
    >
      <div className={styles.roleWrapper}>
        <Icon
          name={role === Roles.PERSONAL ? 'user' : 'store'}
          className={styles.roleIcon}
          size={width > 1000 ? 12 : 6}
        />
      </div>
      {src ? (
        <Image src={src} alt={String(placeholder)} className={styles.avatarImage} fill priority loading="eager" />
      ) : (
        <div className={styles.placeholder}>{placeholder}</div>
      )}
    </button>
  );
};
