import { create } from 'zustand';
import { subscribeWithSelector, persist } from 'zustand/middleware';
import { SellCryptoPaymentType } from '@/constants/payments';
import { BankType } from '@/constants/banks';
import { CryptoCurrency, FiatCurrency, PaymentType, SellCryptoTxResponse } from '@/api/common.types';

interface WebsiteRedirectStoreActionsInterface {
  reset: () => void;
  setData: (data: Partial<WebsiteRedirectData>) => void;
}

export interface WebsiteRedirectData {
  flow?: 'sell' | 'buy' | null;
  sellType?: 'bankTransfer' | 'billPayment' | 'giftCards' | null;
  payType?: 'australia' | 'international' | 'bpay' | 'creditCard' | null;
  get?: string | null;
  accountName?: string | null;
  accountNumber?: string | null;
  description?: string | null;
  country?: string | null;
  swift?: string | null;
  billerCode?: string | null;
  bpayReferenceNumbers?: string | null;
  cardNumber?: string | null;
  cardAmount?: string | null;
  pay?: string | null;
  bsb?: string | null;
  getCurrency?: string | null;
  payCurrency?: string | null;
}

const initialState: WebsiteRedirectData = {
  flow: null,
  sellType: null,
  payType: null,
  get: null,
  accountName: null,
  accountNumber: null,
  description: null,
  country: null,
  swift: null,
  billerCode: null,
  bpayReferenceNumbers: null,
  cardNumber: null,
  cardAmount: null,
  pay: null,
  bsb: null,
  getCurrency: null,
  payCurrency: null,
};

export const useWebsiteRedirect = create(
  subscribeWithSelector(
    persist<WebsiteRedirectData & WebsiteRedirectStoreActionsInterface>(
      (set) => ({
        ...initialState,
        setData(state: Partial<WebsiteRedirectData>) {
          set(() => ({ ...state }));
        },
        reset() {
          set(() => ({ ...initialState }));
        },
      }),
      {
        name: 'websiteRedirect',
      },
    ),
  ),
);

useWebsiteRedirect.getState().reset();
