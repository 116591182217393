import React, { RefObject, useRef } from 'react';
import clsx from 'clsx';
import { useButton } from '@react-aria/button';
import { AriaButtonProps } from '@react-types/button';
import { useCopyToClipboard, useWindowSize } from 'usehooks-ts';
import { toast } from 'react-toastify';
import styles from './CopyButton.module.css';
import { Icon } from '../../Icon/Icon';
import { ToastSuccessIcon } from '../../IconButton';

export const COPY_BUTTON_TESTID = 'copy-button';

export interface CopyButtonProps extends AriaButtonProps<'button'> {
  className?: string;
  iconPosition: 'left' | 'right';
  iconClassName?: string;
  variants: 'details' | 'walletAddress' | 'hash' | 'buyCryptoHash' | 'memo';
  buttonRef?: RefObject<HTMLButtonElement>;
  data?: RefObject<HTMLElement>;
  valueForCopy?: string;
  onCopyClick?: () => void;
}

export const CopyButton = (props: CopyButtonProps) => {
  const { width } = useWindowSize();
  const ref = useRef<HTMLButtonElement>(null);
  const [value, copy] = useCopyToClipboard();

  const {
    children,
    className,
    iconClassName,
    iconPosition = 'left',
    buttonRef = ref,
    data,
    variants = 'details',
    valueForCopy,
    onCopyClick,
    ...otherProps
  } = props;
  const { buttonProps } = useButton(otherProps, buttonRef);

  const defaultStyles = [className, styles.button, styles[variants]];

  const onHandleClick = (variant: 'details' | 'walletAddress' | 'hash' | 'buyCryptoHash' | 'memo') => {
    if (onCopyClick) onCopyClick();

    if (variant === 'details') {
      return copy(String(data?.current?.innerText));
    }
    return copy(valueForCopy ?? String(children));
  };

  const toastText = (variant: string) => {
    switch (variant) {
      case 'details':
        return 'Details copied';
      case 'hash':
        return 'Transaction hash copied';
      case 'buyCryptoHash':
        return 'Transaction hash copied';
      case 'walletAddress':
        return 'Wallet address copied';
      case 'memo':
        return 'Memo copied';
      default:
        return 'Copied';
    }
  };

  return valueForCopy && valueForCopy?.length > 0 ? (
    <button data-testid={COPY_BUTTON_TESTID} {...buttonProps} className={clsx(...defaultStyles)} ref={buttonRef}>
      <div
        className={clsx(styles.icon, {
          [styles.walletIcon]: variants === 'walletAddress' || variants === 'memo',
        })}
        onClick={() => {
          onHandleClick(variants);
          toast.success(toastText(variants), {
            position: toast.POSITION.TOP_CENTER,
            closeButton: false,
            hideProgressBar: true,
            className: 'success-toast-message',
            icon: <ToastSuccessIcon />,
          });
        }}
      >
        <Icon
          name="copy"
          size={(variants === 'walletAddress' || variants === 'memo') && width > 1000 ? 24 : 20}
          className={clsx(styles.icon, iconClassName, styles[iconPosition])}
        />
        <span className={styles.rightText}>{children}</span>
      </div>
    </button>
  ) : null;
};
