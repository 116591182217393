import React, { createContext, ReactNode } from 'react';
import { useCheckboxGroup } from '@react-aria/checkbox';
import {
  useCheckboxGroupState,
  CheckboxGroupState,
} from '@react-stately/checkbox';
import { AriaCheckboxGroupProps } from '@react-types/checkbox';

export interface CheckboxGroupProps extends AriaCheckboxGroupProps {
  className?: string;
  children: ReactNode;
}

export const CheckboxGroupContext = createContext<CheckboxGroupState>({
  validationState: 'valid',
  addValue: () => undefined,
  isDisabled: false,
  isReadOnly: false,
  isSelected: () => false,
  removeValue: () => undefined,
  setValue: () => undefined,
  toggleValue: () => undefined,
  value: [],
});

export const CheckboxGroup = ({ className, ...props }: CheckboxGroupProps) => {
  const { children } = props;
  const state = useCheckboxGroupState(props);
  const { groupProps } = useCheckboxGroup(props, state);

  return (
    <div {...groupProps} className={className}>
      <CheckboxGroupContext.Provider value={state}>
        {children}
      </CheckboxGroupContext.Provider>
    </div>
  );
};

export default CheckboxGroup;
