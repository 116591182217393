import clsx from 'clsx';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { useRef } from 'react';
import { Icon, IconType } from '../../Icon';
import styles from './OverviewCard.module.css';

export interface DataProps {
  periodName?: string;
  value?: number;
}

export interface OverviewCardProps {
  className?: string;
  title?: string;
  isLarge?: boolean;
  isExpanded?: boolean;
  isSingle?: boolean;
  value?: string;
  percent?: string;
  isNegative?: boolean;
  icon?: IconType;
  iconClassName?: string;
  currency?: string | number;
  chartData?: DataProps[];
}

export const OverviewCard = ({
  className,
  title,
  isExpanded,
  isLarge,
  isSingle,
  value,
  percent,
  isNegative,
  icon,
  iconClassName,
  currency,
  chartData,
}: OverviewCardProps) => {
  const ref = useRef(null);

  const newYAxisLabel = (value: DataProps) => `$${value}`;

  return (
    <div className={clsx(styles.card, className)} ref={ref}>
      <div
        className={clsx(styles.mainPanel, {
          [styles.mainPanelLarge]: isLarge,
          [styles.mainPanelSingle]: isSingle,
          [styles.mainPanelExpanded]: isExpanded,
          [styles.mainPanelLargeExpanded]: isExpanded && isLarge,
        })}
      >
        <span
          className={clsx(styles.title, {
            [styles.titleLarge]: isLarge,
            [styles.titleExpanded]: isExpanded,
            [styles.titleLargeExpanded]: isExpanded && isLarge,
          })}
        >
          {title}
        </span>
        <div className={styles.balanceBox}>
          {icon ? (
            <Icon
              className={clsx(styles.balanceIcon, iconClassName, {
                [styles.balanceIconMedium]: isLarge || isExpanded,
                [styles.balanceIconLarge]: isLarge && isExpanded,
                [styles.iconWithoutStroke]: icon === 'transactions' || icon === 'transactionLimit',
              })}
              name={icon}
            />
          ) : (
            <span
              className={clsx(styles.balance, styles.currency, {
                [styles.balanceMedium]: isLarge || isExpanded,
                [styles.balanceLarge]: isLarge && isExpanded,
              })}
            >
              {currency}
            </span>
          )}
          <span
            className={clsx(styles.balance, {
              [styles.balanceMedium]: isLarge || isExpanded,
              [styles.balanceLarge]: isLarge && isExpanded,
            })}
          >
            {value}
          </span>
          {percent !== undefined && (
            <div
              className={clsx(styles.balanceWrapper, {
                [styles.balanceWrapperNegative]: isNegative,
              })}
            >
              <Icon
                className={clsx(styles.percentIconSmall, {
                  [styles.percentIconMedium]: isLarge,
                  [styles.percentIconBig]: isExpanded,
                  [styles.percentIconLarge]: isLarge && isExpanded,
                })}
                name={isNegative ? 'decline' : 'upgrade'}
              />
              {isExpanded && (
                <div
                  className={clsx(styles.percent, {
                    [styles.percentLarge]: isLarge,
                  })}
                >
                  <span>{isNegative ? '-' : '+'}</span>
                  <span>{percent}%</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {isExpanded && chartData && (
        <div className={styles.cardInner}>
          <ResponsiveContainer width="100%" aspect={3}>
            <BarChart data={chartData} margin={{ top: 5, left: 5, bottom: 5, right: isLarge ? 20 : 16 }}>
              <CartesianGrid stroke="#EEEEEE" strokeWidth={0.5} />
              <XAxis
                dataKey="periodName"
                tick={{ fill: '#8894BD', fontSize: isLarge ? 14 : 12 }}
                stroke="transparent"
              />
              <YAxis
                tickFormatter={newYAxisLabel}
                tick={{ fill: '#3B417B', fontSize: isLarge ? 14 : 12 }}
                stroke="transparent"
              />
              <Bar dataKey="value" fill="#75d7ff" barSize={16} radius={[4, 4, 0, 0]} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};
