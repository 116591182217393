import { CSSTransition as TransitionComponent } from 'react-transition-group';
import { ReactElement } from 'react';

interface TransitionProps {
  visibility: boolean;
  duration?: number;
  children: ReactElement;
}

export const Transition = ({
  visibility,
  duration = 0,
  children,
}: TransitionProps) => {
  return (
    <>
      <style>{`.fade-enter-active, .fade-exit-active {
        transition-duration: ${duration}ms;
      }`}</style>
      <TransitionComponent
        unmountOnExit
        mountOnEnter
        in={visibility}
        timeout={duration}
        classNames="fade"
      >
        {children}
      </TransitionComponent>
    </>
  );
};
