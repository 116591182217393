import { useLoadScript } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, Suggestion } from 'react-places-autocomplete';
import { useState, forwardRef, InputHTMLAttributes, useRef, useEffect } from 'react';
import { AriaTextFieldProps } from 'react-aria';
import clsx from 'clsx';
import styles from './GooglePlaces.module.css';

export interface GooglePlacesProps extends AriaTextFieldProps {
  className?: string;
  inputFieldClassName?: string;
  size?: 'medium' | 'large';
  label?: string;
  labelClassName?: string;
  isFilled?: string | boolean;
  placeholder?: string;
  inputExternalProps?: InputHTMLAttributes<HTMLInputElement>;
  onSuccess?: (selectedAddress: { fullAddress: string } & SelectedAddress) => void;
  onError?: (error: string) => void;
}

export interface SelectedAddress {
  country: string;
  countryIso: string;
  state: string;
  town: string;
  street: string;
  postcode: string;
  unitNumber: string;
  buildingNumber: string;
}

const initialSelectedAddress = {
  country: '',
  countryIso: '',
  state: '',
  town: '',
  street: '',
  postcode: '',
  unitNumber: '',
  buildingNumber: '',
};

export const GooglePlaces = forwardRef<HTMLInputElement, GooglePlacesProps>(
  (
    {
      className,
      inputFieldClassName,
      size = 'medium',
      label,
      labelClassName,
      isFilled,
      placeholder,
      onChange,
      onInput,
      inputExternalProps,
      onSuccess,
      onError,
      ...props
    }: GooglePlacesProps,
    ref,
  ) => {
    const { isLoaded } = useLoadScript({
      googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? '',
      libraries: ['places'],
    });
    const [address, setAddress] = useState('');

    useEffect(() => {
      if (props.value) {
        setAddress(props.value);
      }
    }, [props.value]);

    async function handleSelect(address: string) {
      try {
        const geocodeResults = await geocodeByAddress(address);
        const [firstResult] = geocodeResults;
        const { address_components: addressComponents } = firstResult;

        const addressFields = addressComponents.reduce((acc, component) => {
          const { long_name: longName, short_name: shortName, types } = component;

          if (types.includes('country')) {
            return { ...acc, country: longName, countryIso: shortName };
          }
          if (types.includes('administrative_area_level_1')) {
            return { ...acc, state: longName };
          }
          if (types.includes('locality') || types.includes('sublocality')) {
            return { ...acc, town: longName };
          }
          if (types.includes('route')) {
            return { ...acc, street: longName };
          }
          if (types.includes('postal_code')) {
            return { ...acc, postcode: longName };
          }
          if (types.includes('subpremise')) {
            return { ...acc, unitNumber: longName };
          }
          if (types.includes('street_number')) {
            return { ...acc, buildingNumber: longName };
          }
          return acc;
        }, initialSelectedAddress);
        setAddress(address);
        onSuccess?.({ fullAddress: address, ...addressFields });
      } catch (error) {
        onError?.('Error fetching address details');
      }
    }

    const placesAutocompleteRef = useRef<any>(undefined);
    function onOptionClick(suggestion: Suggestion) {
      setAddress(suggestion.description);
      handleSelect(suggestion.description);
      placesAutocompleteRef.current.clearSuggestions();
    }

    return (
      <div className={clsx(styles.wrapper, styles[size], className)}>
        {!!label && (
          <label
            className={clsx(styles.label, labelClassName, {
              [styles.labelLarge]: size === 'large',
            })}
          >
            {label}
          </label>
        )}
        <div
          className={clsx(styles.inputField, {
            [styles.filled]: props.value || isFilled,
            [styles.inputFieldLarge]: size === 'large',
          })}
        >
          {isLoaded ? (
            <PlacesAutocomplete
              ref={placesAutocompleteRef}
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                return (
                  <>
                    <input
                      {...getInputProps()}
                      className={clsx(styles.input, { [styles.inputLarge]: size === 'large' })}
                      ref={ref}
                      aria-label="input"
                      readOnly={props.isReadOnly || inputExternalProps?.readOnly}
                      placeholder={placeholder}
                    />
                    {(loading || !!suggestions.length) && (
                      <div className={styles.dropdownContainer}>
                        {loading ? (
                          <div>Loading...</div>
                        ) : (
                          suggestions.map((suggestion) => {
                            const style = suggestion.active
                              ? { backgroundColor: '#f6f6f6' }
                              : { backgroundColor: '#ffffff' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, { style })}
                                onClick={() => onOptionClick(suggestion)}
                                key={suggestion.description}
                              >
                                <div className={styles.item}>
                                  <span>{suggestion.description}</span>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    )}
                  </>
                );
              }}
            </PlacesAutocomplete>
          ) : (
            <input
              className={clsx(styles.input, { [styles.inputLarge]: size === 'large' })}
              disabled
              aria-label="input"
              placeholder={placeholder}
            />
          )}
        </div>
      </div>
    );
  },
);

GooglePlaces.displayName = 'GooglePlaces';
