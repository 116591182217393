export const GRADIENT_TYPES = [
  'linear-gradient(90deg, #C7D4FE 0%, #97ADFD 100%)',
  'linear-gradient(90deg, #97ADFD 0%, #355EFB 100%)',
  'linear-gradient(90deg, #355EFB 0%, #1743EA 100%)',
  'linear-gradient(90deg, #1743EA 0%, #8894BD 100%)',
  'linear-gradient(90deg, #8894BD 0%, #BDC5E3 100.41%)',
  'linear-gradient(90deg, #BDC5E3 0.43%, #DBE1F2 100%)',
];

export const COLOR_TYPES = [
  '#C7D4FE',
  '#97ADFD',
  '#355efb',
  '#1743ea',
  '#8894BD',
  '#BDC5E3',
  '#DBE1F2',
];
