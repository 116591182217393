import { first, isArray } from 'lodash';
import { useRouter } from 'next/router';

export const useParam = function useParam<T = string>(
  name: string,
): { param: T | undefined; isReady: boolean } {
  const router = useRouter();

  let param = router.query?.[name];

  if (isArray(param)) {
    param = first(param);
  }

  return { param: param as T | undefined, isReady: router?.isReady };
};
