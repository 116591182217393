import { useRef } from 'react';
import clsx from 'clsx';
import { useButton, AriaButtonProps } from 'react-aria';
import styles from './IconButton.module.css';
import { Icon, IconType } from '../Icon';

export interface IconButtonProps extends AriaButtonProps<'button'> {
  size?: 'medium' | 'small';
  icon: IconType;
}

export const IconButton = ({
  size = 'medium',
  icon,
  ...props
}: IconButtonProps) => {
  const ref = useRef(null);
  const { buttonProps } = useButton(props, ref);

  return (
    <button
      {...buttonProps}
      className={clsx(styles.button, styles[size], buttonProps.className)}
      ref={ref}
    >
      {!!icon && (
        <Icon
          name={icon}
          size={size === 'medium' ? 24 : 20}
          className={styles.icon}
        />
      )}
    </button>
  );
};

export const ToastSuccessIcon = () => {
  return (
    <div className={styles.iconWrapper}>
      <Icon name="check" size={20} fill="#159570" />
    </div>
  );
};
