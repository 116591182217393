import React, { useRef } from 'react';
import clsx from 'clsx';
import { mergeProps } from '@react-aria/utils';
import { AriaSwitchProps } from '@react-types/switch';
import { useSwitch } from '@react-aria/switch';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useToggleState } from '@react-stately/toggle';
import { useFocusRing } from '@react-aria/focus';
import styles from './Switch.module.css';
import { onEnterPress } from '../../../../utils/events';

export interface SwitchProps extends AriaSwitchProps {
  className?: string;
  type?: 'dark' | 'light';
}

export const Switch = ({ className, type = 'dark', ...props }: SwitchProps) => {
  const state = useToggleState(props);
  const ref = useRef<HTMLInputElement>(null);
  const { focusProps, isFocusVisible } = useFocusRing();
  const { inputProps } = useSwitch(
    {
      ...props,
      onKeyUp: onEnterPress(props, state),
    },
    state,
    ref,
  );

  return (
    <label
      className={clsx(styles.label, styles[type], className, {
        [styles.focused]: isFocusVisible,
        [styles.labelSelectedLight]: state.isSelected && type !== 'dark',
        [styles.labelSelectedDark]: state.isSelected && type === 'dark',
      })}
    >
      <VisuallyHidden>
        <input {...mergeProps(focusProps, inputProps)} ref={ref} />
      </VisuallyHidden>
      <span className={styles.switch}>
        <span
          className={clsx(styles.circle, {
            [styles.circleLight]: type !== 'dark',
            [styles.circleSelectedLight]: state.isSelected && type !== 'dark',
            [styles.circleSelectedDark]: state.isSelected && type === 'dark',
          })}
        />
      </span>
    </label>
  );
};

export default SwitchProps;
