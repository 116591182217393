import { useRef } from 'react';
import clsx from 'clsx';
import { useButton, AriaButtonProps } from 'react-aria';
import styles from './FabButton.module.css';
import { Icon, IconType } from '../Icon';

export interface FabButtonProps extends AriaButtonProps<'button'> {
  icon: IconType;
}

export const FabButton = ({
  icon,
  ...props
}: FabButtonProps) => {
  const ref = useRef(null);
  const { buttonProps } = useButton(props, ref);

  return (
    <button
      {...buttonProps}
      className={clsx(styles.button, buttonProps.className)}
      ref={ref}
    >
      {!!icon && (
        <Icon
          name={icon}
          size={28}
          className={styles.icon}
        />
      )}
    </button>
  );
};
