import React, { useRef } from 'react';
import { useCheckbox } from '@react-aria/checkbox';
import { AriaCheckboxProps } from '@react-types/checkbox';
import { useToggleState } from '@react-stately/toggle';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { onEnterPress } from '@/utils/events';
import CheckboxContent, { CheckboxCoreProps } from './CheckboxContent';

export type CheckboxProps = AriaCheckboxProps & CheckboxCoreProps;

export const Checkbox = ({ className, size = 'medium', ...props }: CheckboxProps) => {
  const { children, isDisabled } = props;
  const ref = useRef<HTMLInputElement>(null);
  const state = useToggleState(props);
  const { inputProps } = useCheckbox(
    {
      ...props,
      onKeyUp: onEnterPress(props, state),
    },
    state,
    ref,
  );
  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <CheckboxContent
      className={className}
      isDisabled={isDisabled}
      isFocused={isFocusVisible}
      isSelected={state.isSelected}
      props={mergeProps(inputProps, focusProps)}
      size={size}
      ref={ref}
    >
      {children}
    </CheckboxContent>
  );
};

export default Checkbox;
