import clsx from 'clsx';
import { PaymentStatusType } from '@/constants/paymentStatuses';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { formatDateAndTime, getLinkToTxInBlockchain, truncateString } from '@/utils/functions';
import { Spinner } from '@/components/common/Spinner';
import { useGetMerchantTxn } from '@/api/queryHooks/useMerchantCryptoController';
import Image from 'next/image';
import { useSellCrypto } from '@/store/sellCrypto';
import { shallow } from 'zustand/shallow';
import Link from 'next/link';
import completed from '../../../public/animations/payment-completed.json';
import styles from './SellCryptoLoader.module.css';
import { QrCode } from '../QrCode';
import { MonitoredBy } from '../MonitoredBy';
import { CopyButton } from '../buttons';
import { Icon } from '../Icon';

interface SellCryptoLoaderProps {
  status: PaymentStatusType;
  className?: string;
  txId?: string;
  qrValue?: string;
  isHosted?: boolean;
  isQRShown?: boolean | null;
  isConnectedWallet?: boolean | null;
  onMemoWarningClick?: () => void;
}

export const SellCryptoLoader = ({
  status,
  className,
  qrValue = '',
  txId,
  isHosted,
  isQRShown = true,
  isConnectedWallet,
  onMemoWarningClick,
}: SellCryptoLoaderProps) => {
  const { isDesktop } = useMediaQuery();
  const { data: getTransactionData } = useGetMerchantTxn([{ id: txId }, { enabled: false }]);
  const sellState = useSellCrypto((state) => state, shallow);

  switch (status) {
    case PaymentStatusType.Locked:
    case PaymentStatusType.Expired:
      return (
        <div className={clsx({ [styles.hostedPage]: isHosted })}>
          {isQRShown && (
            <div className={clsx(styles.qr, className)}>
              {isDesktop ? (
                <QrCode
                  codeSize={!isHosted ? 220 : 183}
                  logoSize={!isHosted ? 36 : 26}
                  quietZone={!isHosted ? 18 : 12}
                  value={qrValue}
                  logoSrc="/assets/RP-logo.svg"
                />
              ) : (
                <div className={styles.mobileQRWrapper}>
                  <QrCode codeSize={140} logoSize={18} quietZone={10} value={qrValue} logoSrc="/assets/RP-logo.svg" />
                </div>
              )}
              <div className={styles.qrWalletWrapper}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {status === PaymentStatusType.Locked || status === PaymentStatusType.Expired ? (
                  <>
                    {!isConnectedWallet && <span className={styles.checkoutDataTitle}>Wallet Address:</span>}
                    <div className={styles.walletWithSendBtn}>
                      {!isConnectedWallet && (
                        <CopyButton
                          className={styles.copyButton}
                          iconClassName={styles.copyIcon}
                          iconPosition="right"
                          variants="walletAddress"
                          valueForCopy={sellState.txData?.depositAddress ?? sellState.txData?.systemDepositAddress}
                        >
                          {truncateString({
                            string: sellState.txData?.depositAddress ?? sellState.txData?.systemDepositAddress,
                          })}
                        </CopyButton>
                      )}
                    </div>
                  </>
                ) : sellState.txData?.blockchainTxHash ? (
                  <>
                    <span className={styles.checkoutDataTitle}>Transaction Hash:</span>
                    <CopyButton
                      className={styles.copyButton}
                      iconClassName={styles.copyIcon}
                      variants="hash"
                      iconPosition="right"
                      valueForCopy={sellState.txData?.blockchainTxHash}
                    >
                      <Link
                        href={getLinkToTxInBlockchain(
                          sellState.txData?.cryptoCurrency,
                          sellState.txData?.blockchainTxHash,
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {truncateString({ string: sellState.txData?.blockchainTxHash })}
                      </Link>
                    </CopyButton>
                  </>
                ) : null}
              </div>
              <p className={styles.walletSaveWarning}>
                This is a temporary payment address, please do not save for future payments..
              </p>{' '}
              {sellState.txData?.hasMemo && (
                <>
                  <div className={styles.qrMemoWrapper}>
                    <div className={styles.memoWrapper}>
                      <span className={styles.checkoutDataMemoTitle}>Memo:</span>
                      <CopyButton
                        className={styles.copyButton}
                        iconClassName={styles.copyIcon}
                        variants="memo"
                        iconPosition="right"
                        valueForCopy={sellState.txData?.depositMemo || ''}
                      >
                        {truncateString({ string: sellState.txData?.depositMemo || '' })}
                      </CopyButton>
                    </div>
                  </div>
                  <div className={styles.memoWarningWrapper}>
                    <div onClick={onMemoWarningClick}>
                      <Icon name="infoCircled" className={styles.memoWarningIcon} />
                    </div>
                    <span className={styles.memoWarningText}>
                      Transactions without RelayPay&apos;s memo will be lost.
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
          {!isQRShown && (
            <div className={clsx(styles.qr, className)}>
              <a href="https://www.wentravel.com/" target="_blank" rel="noreferrer">
                <Image
                  style={{ objectPosition: 'center', objectFit: 'contain' }}
                  className={styles.banner}
                  width={isDesktop ? 302 : 250}
                  height={isDesktop ? 302 : 250}
                  src="/assets/wentravel-banner.png"
                  alt="Wentravel"
                  priority
                  loading="eager"
                />
              </a>
            </div>
          )}
          {isHosted && isDesktop && <MonitoredBy className={styles.monitoredBy} />}
        </div>
      );
    case PaymentStatusType.Replaced:
    case PaymentStatusType.Completed:
      return (
        <div className={clsx(styles.paymentLoader, { [styles.hostedPaymentLoader]: isHosted })}>
          <Spinner animationData={completed} side={isDesktop ? 64 : 60} />
          <p className={styles.resultTitle}>Transfer Completed</p>
          <div className={styles.resultRow}>
            <p className={styles.data}>Thank you for using RelayPay.</p>
          </div>
        </div>
      );

    default: {
      const { date, time } = formatDateAndTime(
        getTransactionData?.updated ? getTransactionData?.updated : getTransactionData?.created,
      );
      return (
        <div className={clsx(styles.paymentLoader, { [styles.hostedPaymentLoader]: isHosted })}>
          <Spinner side={isDesktop ? 64 : 60} />
          <p className={styles.resultTitle}>Payment Processing</p>
          <div className={styles.resultRow}>
            <p className={styles.data}>{date}</p>
            <p className={styles.time}>{time}</p>
          </div>
        </div>
      );
    }
  }
};
