import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { url, axiosClient } from '@/api';
import { AxiosError } from 'axios';
import { EntityType, AccountPurpose, ErrorObject } from '../common.types';
import { ACCESS_TOKEN } from '@/constants/storage';

interface UpdateUserInfoPayload {
  mobile: string;
  firstName: string;
  lastName: string;
}
interface BusinessDetailsPayload {
  organizationName: string;
  website: string;
  customers: string[];
  entityType: EntityType;
  accountPurpose: AccountPurpose;
  abn: string;
}
export interface AddressPayload {
  fullAddress: null | string;
  country: null | string;
  state: null | string;
  town: null | string;
  street: null | string;
  postcode: null | string;
  unitNumber: null | string;
  buildingNumber: null | string;
}

export function useUpdateUserInfo<TContext>(
  options?: Omit<
    UseMutationOptions<void, AxiosError<ErrorObject>, UpdateUserInfoPayload, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<void, AxiosError<ErrorObject>, UpdateUserInfoPayload, TContext> {
  const key = ['UpdateBusinessUserInfo'];
  const accessToken = localStorage.getItem(ACCESS_TOKEN)?.replaceAll('"', '');

  return useMutation(
    (body: UpdateUserInfoPayload) => axiosClient.put(url.userController.updateBusinessUserInfo, body, {
      headers: { 'Authorization': `Bearer ${accessToken}` },
    }),
    {
      ...options,
      mutationKey: key,
    },
  );
}

export function useUpdateBusinessDetails<TContext>(
  options?: Omit<
    UseMutationOptions<void, AxiosError<ErrorObject>, BusinessDetailsPayload, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<void, AxiosError<ErrorObject>, BusinessDetailsPayload, TContext> {
  const key = ['UpdateBusinessDetails'];

  return useMutation(
    (body: BusinessDetailsPayload) => axiosClient.put(url.userController.updateBusinessDetails, body),
    {
      ...options,
      mutationKey: key,
    },
  );
}

export function useUpdateAddress<TContext>(
  options?: Omit<
    UseMutationOptions<void, AxiosError<ErrorObject>, AddressPayload, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<void, AxiosError<ErrorObject>, AddressPayload, TContext> {
  const key = ['UpdateAddress'];

  return useMutation((body: AddressPayload) => axiosClient.post(url.userController.address, body), {
    ...options,
    mutationKey: key,
  });
}

export function useGetAddress<TSelectData = AddressPayload, TError = AxiosError<ErrorObject>>(
  options?: Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['GetAddress'],
    () => axiosClient.get(url.userController.address),
    options,
  );
}
