import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query';
import { axiosClient, url } from '../';
import { AxiosError } from 'axios';
import { AgeGroup, ErrorObject, KYBStatus } from './../common.types';


export function useAcceptTerms<TContext>(
    options?: Omit<UseMutationOptions<void, AxiosError<ErrorObject>, void, TContext>, 'mutationKey' | 'mutationFn'>,
  ): UseMutationResult<void, AxiosError<ErrorObject>, void, TContext> {
    const key = ['AcceptTerms'];
  
    return useMutation(() => axiosClient.post(url.termsController), {
      ...options,
      mutationKey: key,
    });
  }