import { ReactNode } from 'react';
import clsx from 'clsx';
import styles from './InfoLabel.module.css';
import { Icon, IconType } from '../Icon';

export interface InfoLabelProps {
  icon: IconType;
  size?: number;
  children: ReactNode;
  className?: string;
}

export const InfoLabel = ({ icon, size, children, className }: InfoLabelProps) => {
  return (
    <div className={clsx(styles.wrapper, className)}>
      <span className={styles.text}>{children}</span>
      <Icon name={icon} size={size} className={styles.icon} />
    </div>
  );
};
