import { ReactNode, useRef } from 'react';
import type { Node } from '@react-types/shared';
import clsx from 'clsx';
import { AriaTabPanelProps, AriaTabProps, useTab, useTabList, useTabPanel } from 'react-aria';
import { TabListProps, TabListState, useTabListState } from 'react-stately';
import styles from './Tabs.module.css';
import { Icon, IconType } from '../Icon';
import { Button, CloseButton } from '../buttons';

export interface TabsProps extends TabListProps<Record<string, unknown>> {
  tabsClassName?: string;
  tabClassName?: string;
  tabListClassName?: string;
  tabPanelClassName?: string;
  orientation?: 'horizontal' | 'vertical';
  type: 'default' | 'header' | 'rounded' | 'square' | 'date' | 'sell';
  icon?: IconType;
  chevron?: boolean;
}

export interface TabProps extends AriaTabProps {
  tabClassName?: string;
  item: Node<unknown>;
  orientation?: 'horizontal' | 'vertical';
  state: TabListState<unknown>;
  type: 'default' | 'header' | 'rounded' | 'square' | 'date' | 'sell';
  icon?: IconType;
}

export interface HeaderTabProps extends AriaTabProps {
  tabClassName?: string;
  item: Node<unknown>;
  state: TabListState<unknown>;
  chevron?: boolean;
}

export interface NavigationTabProps {
  tabClassName?: string;
  label: string;
  chevron?: boolean;
  isActive?: boolean;
}

export interface TabPanelProps extends AriaTabPanelProps {
  state: TabListState<unknown>;
  tabPanelClassName?: string;
}

export interface LinkTabProps {
  className?: string;
  isActive?: boolean;
  children?: ReactNode;
  onClick?: () => void;
}

export interface LinkTabPanelProps {
  className?: string;
  children?: ReactNode;
}

export interface LinkTabListProps {
  className?: string;
  children?: ReactNode;
}

export const Tabs = ({
  orientation = 'horizontal',
  tabsClassName,
  tabClassName,
  tabListClassName,
  tabPanelClassName,
  type = 'default',
  icon,
  chevron,
  ...props
}: TabsProps) => {
  const state = useTabListState(props);
  const ref = useRef(null);
  const { tabListProps } = useTabList(props, state, ref);

  return (
    <div
      className={clsx(styles.tabs, tabsClassName, {
        [styles.verticalTabs]: orientation === 'vertical',
      })}
    >
      <div
        className={clsx(styles.tabList, tabListClassName, {
          [styles.verticalTabList]: orientation === 'vertical',
          [styles.sellTabList]: type === 'sell',
          [styles.dateTabList]: type === 'date',
        })}
        {...tabListProps}
        ref={ref}
      >
        {type !== 'header' ? (
          <>
            {[...state.collection].map((item) => (
              <Tab
                key={item.key}
                item={item}
                state={state}
                orientation={orientation}
                tabClassName={tabClassName}
                type={type}
                icon={icon}
              />
            ))}
            {type === 'date' && <CloseButton iconClassName={styles.closeButtonIcon} className={styles.closeButton} />}
          </>
        ) : (
          [...state.collection].map((item) => (
            <HeaderTab key={item.key} item={item} state={state} tabClassName={tabClassName} chevron={chevron} />
          ))
        )}
      </div>
      <TabPanel key={state.selectedItem?.key} state={state} tabPanelClassName={tabPanelClassName} />
    </div>
  );
};

const Tab = ({ item, state, tabClassName, type, icon, orientation = 'horizontal' }: TabProps) => {
  const { key, rendered } = item;
  const ref = useRef(null);
  const { tabProps, isSelected } = useTab({ key }, state, ref);
  return (
    <div
      {...tabProps}
      ref={ref}
      className={clsx(styles.tab, tabClassName, {
        [styles.verticalTab]: orientation === 'vertical',
        [styles.selectedTab]: type === 'default' && isSelected,
        [styles.roundedTab]: type === 'rounded',
        [styles.selectedRoundedTab]: type === 'rounded' && isSelected,
        [styles.squareTab]: type === 'square',
        [styles.selectedSquareTab]: type === 'square' && isSelected,
        [styles.sellTab]: type === 'sell',
        [styles.selectedSellTab]: type === 'sell' && isSelected,
        [styles.dateTab]: type === 'date',
        [styles.selectedDateTab]: type === 'date' && isSelected,
      })}
    >
      {icon && <Icon name={icon} size={24} className={styles.tabIcon} />}
      {rendered}
    </div>
  );
};

const HeaderTab = ({ item, state, tabClassName, chevron }: HeaderTabProps) => {
  const { key, rendered } = item;
  const ref = useRef(null);
  const { tabProps, isSelected } = useTab({ key }, state, ref);
  return (
    <div
      {...tabProps}
      ref={ref}
      className={clsx(styles.tab, styles.headerTab, tabClassName, {
        [styles.selectedHeaderTab]: isSelected,
      })}
    >
      <div className={styles.headerTabInner}>
        {rendered}
        {chevron && (
          <Button
            variants="subtle"
            size="mediumSubtle"
            icon="chevronDown"
            className={styles.tabChevron}
            onPress={undefined}
          />
        )}
      </div>
      <span className={clsx(styles.tabDash, { [styles.activeTabDash]: isSelected })} />
    </div>
  );
};

export const NavigationTab = ({ tabClassName, label, chevron, isActive }: NavigationTabProps) => {
  const ref = useRef(null);
  return (
    <div
      ref={ref}
      className={clsx(styles.tab, styles.headerTab, styles.navigationTab, tabClassName, {
        [styles.selectedHeaderTab]: isActive,
      })}
    >
      <div className={styles.navigationTabInner}>
        <span className={styles.headerTabInner}>{label}</span>
        {chevron && (
          <Button
            variants="subtle"
            size="mediumSubtle"
            icon="chevronDown"
            className={styles.tabChevron}
            onPress={undefined}
          />
        )}
      </div>
      <span
        className={clsx(styles.tabDash, {
          [styles.activeTabDash]: isActive,
        })}
      />
    </div>
  );
};

const TabPanel = ({ state, tabPanelClassName, ...props }: TabPanelProps) => {
  const ref = useRef(null);
  const { tabPanelProps } = useTabPanel(props, state, ref);
  return (
    <div {...tabPanelProps} ref={ref} className={clsx(styles.tabPanel, tabPanelClassName)}>
      {state.selectedItem?.props.children}
    </div>
  );
};

export const LinkTab = ({ className, isActive, children, onClick }: LinkTabProps) => {
  const ref = useRef(null);
  return (
    <li
      onClick={onClick}
      ref={ref}
      className={clsx(styles.tab, styles.roundedTab, className, {
        [styles.selectedRoundedTab]: isActive,
      })}
    >
      {children}
    </li>
  );
};

export const LinkTabList = ({ className, children }: LinkTabListProps) => {
  const ref = useRef(null);
  return (
    <ul ref={ref} className={clsx(styles.tabList, className)}>
      {children}
    </ul>
  );
};

export const LinkTabPanel = ({ className, children }: LinkTabPanelProps) => {
  const ref = useRef(null);
  return (
    <div ref={ref} className={clsx(styles.tabPanel, className)}>
      {children}
    </div>
  );
};
