import React, { useRef } from 'react';
import clsx from 'clsx';
import { mergeProps } from '@react-aria/utils';
import { AriaSwitchProps } from '@react-types/switch';
import { useSwitch } from '@react-aria/switch';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useToggleState } from '@react-stately/toggle';
import { useFocusRing } from '@react-aria/focus';
import styles from './Toggle.module.css';
import { onEnterPress } from '../../../utils/events';

export interface ToggleProps extends AriaSwitchProps {
  className?: string;
  size?: 'big' | 'small';
}

export const Toggle = ({ className, size = 'big', ...props }: ToggleProps) => {
  const state = useToggleState(props);
  const ref = useRef<HTMLInputElement>(null);
  const { focusProps, isFocusVisible } = useFocusRing();
  const { inputProps } = useSwitch(
    {
      ...props,
      onKeyUp: onEnterPress(props, state),
    },
    state,
    ref,
  );

  return (
    <div
      className={clsx(styles.toggleWrapper, {
        [styles.toggleWrapperSmall]: size === 'small',
      })}
    >
      <label
        className={clsx(styles.label, className, {
          [styles.focused]: isFocusVisible,
        })}
      >
        <VisuallyHidden>
          <input {...mergeProps(focusProps, inputProps)} ref={ref} />
        </VisuallyHidden>
        <span
          className={clsx(
            styles.toggleItem,
            styles.toggleItemFirstChild,
            styles[size],
            {
              [styles.toggleItemChecked]: !state.isSelected,
              [styles.focused]: isFocusVisible,
            },
          )}
        >
          Revoke
        </span>
        <span
          className={clsx(styles.toggleItem, styles[size], {
            [styles.toggleItemChecked]: state.isSelected,
            [styles.focused]: isFocusVisible,
          })}
        >
          Active
        </span>
      </label>
    </div>
  );
};
