/* eslint-disable no-unused-vars */
import { OverlayTriggerState, useOverlayTriggerState } from 'react-stately';

import * as React from 'react';
import {
  usePopover,
  DismissButton,
  Overlay,
  AriaPopoverProps,
  useOverlayPosition,
  useOverlayTrigger,
} from '@react-aria/overlays';
import clsx from 'clsx';
import { AriaButtonProps, mergeProps } from 'react-aria';
import { ReactNode } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import styles from './Popover.module.css';
import { Transition } from '../Transition';

interface PopoverProps extends Omit<AriaPopoverProps, 'popoverRef'> {
  children: React.ReactNode;
  state: OverlayTriggerState;
  className?: string;
  popoverRef?: React.RefObject<HTMLDivElement>;
  maxHeightScroll?: string;
  blured?: boolean;
  width?: React.CSSProperties['width'];
  isFixed?: boolean;
}

interface PopoverWithMouseOverProps {
  children: React.ReactNode;
  triggerSrc: string;
  triggerWidth?: number;
  triggerHeight?: number;
  className?: string;
  state: OverlayTriggerState;
  isHoverActive?: boolean;
}

export function Popover(props: PopoverProps) {
  const router = useRouter();
  const ref = React.useRef<HTMLDivElement>(null);
  const {
    popoverRef = ref,
    isFixed = true,
    state,
    children,
    className,
    isNonModal,
    maxHeightScroll,
    blured,
    offset,
    placement,
    width,
    ...otherProps
  } = props;

  React.useEffect(() => {
    const handleRouteChange = () => {
      state.close();
    };

    router?.events.on?.('routeChangeStart', handleRouteChange);

    return () => {
      router?.events.off?.('routeChangeStart', handleRouteChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { popoverProps } = usePopover(
    {
      ...otherProps,
      popoverRef,
      offset,
      placement,
    },
    state,
  );

  return isFixed ? (
    <Overlay>
      <Transition duration={200} visibility={state.isOpen}>
        <div
          {...popoverProps}
          style={{ width, ...popoverProps.style }}
          ref={popoverRef}
          className={clsx(styles.wrapper, className)}
        >
          {!isNonModal && <DismissButton onDismiss={state.close} />}
          {children}
          {maxHeightScroll && blured && <div className={styles.listBlur} />}
          <DismissButton onDismiss={state.close} />
        </div>
      </Transition>
    </Overlay>
  ) : (
    <Transition duration={200} visibility={state.isOpen}>
      <div
        {...popoverProps}
        style={{ width, ...popoverProps.style }}
        ref={popoverRef}
        className={clsx(styles.wrapper, className)}
      >
        {!isNonModal && <DismissButton onDismiss={state.close} />}
        {children}
        {maxHeightScroll && blured && <div className={styles.listBlur} />}
        <DismissButton onDismiss={state.close} />
      </div>
    </Transition>
  );
}

interface PopoverWithTriggerProps extends Omit<PopoverProps, 'state' | 'triggerRef'> {
  renderTrigger: ({
    state,
    triggerProps,
    triggerRef,
  }: {
    state: OverlayTriggerState;
    triggerProps: AriaButtonProps<'button'>;
    triggerRef: React.RefObject<HTMLButtonElement>;
  }) => ReactNode;
  containerClassName?: string;
  dialogClassName?: string;
  shouldUpdatePosition?: boolean;
}

// eslint-disable-next-line operator-linebreak
export const PopoverWithTrigger: React.FC<PopoverWithTriggerProps> = function PopoverWithTrigger({
  className,
  dialogClassName,
  containerClassName,
  containerPadding,
  crossOffset,
  offset = 12,
  placement = 'bottom right',
  shouldFlip,
  shouldUpdatePosition,
  children,
  renderTrigger,
  ...props
}) {
  const state = useOverlayTriggerState({});

  const triggerRef = React.useRef<HTMLButtonElement>(null);
  const overlayRef = React.useRef<HTMLDivElement>(null);

  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, state, triggerRef);

  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    isOpen: state.isOpen,
    containerPadding,
    crossOffset,
    offset,
    placement,
    shouldFlip,
    shouldUpdatePosition,
  });

  return (
    <>
      <Overlay>
        <Transition duration={200} visibility={state.isOpen}>
          <Popover
            {...mergeProps(overlayProps, positionProps, props)}
            state={state}
            triggerRef={triggerRef}
            isNonModal
            offset={offset}
            placement={placement}
            className={className}
          >
            {children}
          </Popover>
        </Transition>
      </Overlay>

      {renderTrigger({
        state,
        triggerProps,
        triggerRef,
      })}
    </>
  );
};

export const PopoverWithMouthOver: React.FC<PopoverWithMouseOverProps> = ({
  triggerSrc,
  children,
  triggerHeight = 40,
  triggerWidth = 40,
  className,
  isHoverActive = true,
  state,
}) => {
  const onMouseEnterTrigger = () => {
    state.open();
  };

  const onMouseLeaveTrigger = () => {
    state.close();
  };

  const onClickHandler = () => {
    state.toggle();
  };

  return (
    <div
      className={clsx(styles.popoverWithMouseBody, className)}
      onMouseEnter={isHoverActive ? onMouseEnterTrigger : undefined}
      onMouseLeave={isHoverActive ? onMouseLeaveTrigger : undefined}
    >
      <div
        style={{
          width: `${triggerWidth}px`,
          height: `${triggerHeight}px`,
        }}
        className={styles.popoverWithMouseTrigger}
        onClick={onClickHandler}
      >
        <Image src={triggerSrc} alt={'Popover trigger image'} fill priority loading="eager" />
      </div>
      <Transition duration={200} visibility={state.isOpen}>
        <div className={styles.popoverWithMouseModal}>{children}</div>
      </Transition>
    </div>
  );
};
