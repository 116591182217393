import * as React from 'react';
import type { ComboBoxProps } from '@react-types/combobox';
import { Node } from '@react-types/shared';
import { useComboBoxState } from 'react-stately';
import { useComboBox, useFilter, useButton } from 'react-aria';
import clsx from 'clsx';
import { useWindowSize } from 'usehooks-ts';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { ListBox } from './SelectList';
import { Popover } from '../../Popover';
import { Input } from '../Input';
import styles from './Select.module.css';
import { Label } from '../../Label';

export interface SelectSearchProps extends ComboBoxProps<Node<unknown>> {
  className?: string;
  popupClassName?: string;
  placeholder?: string;
  flag?: React.ReactNode;
  defaultFlag?: string;
  blur?: boolean;
  errorMessage?: string;
  checkMessage?: string;
  checkLabel?: boolean;
  size?: 'medium' | 'large';
  inputIconClassName?: string;
  themeColor?: string;
  labelClassName?: string;
  borderRadius?: string;
  filterFn?: (arg?: any) => boolean;
  rightIcon?: React.ReactNode;
  defaultSelectedKey?: React.Key;
  inputClassName?: string;
  showMoreNumber?: number;
  isShowMore?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

export function Select(props: SelectSearchProps) {
  const { width } = useWindowSize();
  const {
    placeholder,
    flag,
    className,
    popupClassName,
    size,
    inputIconClassName,
    themeColor,
    labelClassName,
    borderRadius,
    filterFn,
    rightIcon,
    defaultSelectedKey,
    inputClassName,
    isShowMore,
    showMoreNumber,
    disabled = false,
    readOnly = false,
  } = props;
  const { contains } = useFilter({ sensitivity: 'base' });
  const state = useComboBoxState({ ...props, defaultFilter: filterFn || contains });
  const { isMobile } = useMediaQuery();

  const inputRef = React.useRef(null);
  const listBoxRef = React.useRef(null);
  const popoverRef = React.useRef(null);

  const {
    inputProps,
    listBoxProps,
    buttonProps: triggerProps,
  } = useComboBox(
    {
      ...props,
      inputRef,
      listBoxRef,
      popoverRef,
    },
    state,
  );

  const clearButtonRef = React.useRef(null);
  const { buttonProps } = useButton(triggerProps, clearButtonRef);
  const outerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (props.defaultInputValue && !props.inputValue) {
      state.setInputValue(props.defaultInputValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.inputValue, props.defaultInputValue]);

  React.useEffect(() => {
    if (props.defaultFlag && !flag && !props.inputValue) {
      state.setSelectedKey(props.defaultFlag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultFlag, flag]);

  React.useEffect(() => {
    if (defaultSelectedKey) {
      state.setSelectedKey(defaultSelectedKey ?? '');
    }
  }, [defaultSelectedKey, state]);

  return (
    <div className={clsx(styles.container, className)}>
      <div ref={outerRef}>
        <Input
          inputClassName={inputClassName}
          borderRadius={borderRadius}
          labelClassName={labelClassName}
          themeColor={themeColor}
          label={props.label as string}
          buttonIcon="chevronDown"
          iconButtonClassName={clsx(styles.icon, inputIconClassName)}
          placeholder={placeholder}
          inputExternalProps={inputProps}
          buttonExternalProps={buttonProps}
          ref={inputRef}
          isDisabled={disabled || props.isDisabled}
          flag={flag ?? props.defaultFlag}
          size={size || (width > 1000 ? 'large' : 'medium')}
          errorMessage={props.errorMessage}
          isFilled={state.inputValue !== ''}
          onBlur={() => undefined}
          secondButtonIcon={rightIcon}
          isReadOnly={readOnly}
        />
      </div>
      {state.isOpen && (
        <Popover
          popoverRef={popoverRef}
          triggerRef={outerRef}
          state={state}
          width={outerRef.current?.clientWidth}
          placement="bottom right"
          className={styles.popover}
          isFixed={false}
        >
          <ListBox
            {...listBoxProps}
            listBoxRef={listBoxRef}
            state={state}
            popupClassName={popupClassName}
            isShowMore={!isMobile ? isShowMore : false}
            showMoreNumber={showMoreNumber}
          />
          {props.blur && <div className={styles.listBlur} />}
        </Popover>
      )}
      {!!props.checkMessage && (
        <div className={styles.error}>
          <p className={styles.checkText}>{props.checkMessage}</p>
        </div>
      )}
      {props.checkLabel && (
        <Label className={clsx(styles.copyButtonLabel, styles.checkLabel)} icon="check" size="medium" color="green">
          Identified
        </Label>
      )}
    </div>
  );
}
