import reduce from 'lodash/reduce';
import map from 'lodash/map';
import React, { FC, PropsWithChildren, forwardRef, useMemo } from 'react';
import {
  CurrencyCodeWithFlagInterface,
  useSelectedCurrency,
} from '@/components/common/form/SelectCurrencyCountry/useSelectedCurrency';
import { FiatObjectType } from '@/api/queryHooks/useFxController';
import { SelectRC, SelectRCProps } from '../SelectRC';
import { ItemType } from '../SelectRC/SelectRCOption';
import styles from './SelectRCCurrencyCountry.module.css';

interface Props extends Omit<SelectRCProps, 'options'> {
  className?: string;
  isCurrency?: boolean;
  fiatList?: FiatObjectType;
}

export const SelectRCCurrencyCountry: FC<PropsWithChildren<Props>> = forwardRef(
  ({ value, className, children, isCurrency, fiatList, ...rest }, ref) => {
    const { currencyCodesWithFlags } = useSelectedCurrency(String(value?.value));

    const renderItem = ({ currency, flag }: Omit<CurrencyCodeWithFlagInterface, 'countryCode'>) => (
      <div className={styles.row}>
        <div className={styles.flag}>{flag}</div>
        {currency}
      </div>
    );

    const mappedData: ItemType[] = useMemo(() => {
      if (fiatList) {
        return reduce(
          currencyCodesWithFlags,
          (a, { currency, flag }) => {
            if (fiatList?.[currency]) {
              a.push({
                value: currency,
                label: renderItem({ currency, flag }),
              });
            }
            return a;
          },
          [] as ItemType[],
        );
      }
      return map(currencyCodesWithFlags, ({ currency, flag }) => {
        return {
          value: currency,
          label: renderItem({ currency, flag }),
        };
      });
    }, [fiatList, currencyCodesWithFlags]);

    return (
      <SelectRC {...rest} options={mappedData} value={value} isCurrency={isCurrency} className={className}>
        {children}
      </SelectRC>
    );
  },
);

SelectRCCurrencyCountry.displayName = 'SelectRCCurrencyCountry';
