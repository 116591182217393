import Lottie from 'react-lottie';
import processing from '@/public/animations/payment-processing.json';

interface SpinnerProps {
  side?: number;
  animationData?: any;
  padding?: number;
}
export const Spinner = ({ side, animationData, padding }: SpinnerProps) => {
  function getSize() {
    if (!!side && !!padding) return side + padding * 2;
    if (!!side && !padding) return side;
    if (!side && !!padding) return 64 + padding * 2;
    return 64;
  }
  return (
    <Lottie
      isClickToPauseDisabled
      style={{ padding }}
      options={{ loop: true, autoplay: true, animationData: animationData ?? processing }}
      width={getSize()}
      height={getSize()}
      isPaused={false}
      ariaRole=""
    />
  );
};
