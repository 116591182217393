import clsx from 'clsx';
import { useRef } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { PaymentStatusType } from '@/constants/paymentStatuses';
import styles from './Countdown.module.css';

export interface CountdownProps {
  className?: string;
  duration?: number;
  size?: number;
  strokeWidth?: number;
  description?: string;
  status: PaymentStatusType;
  onComplete?: () => void;
}

export const Countdown = ({
  className,
  size = 44,
  strokeWidth = 3,
  duration = 3600,
  description,
  status,
  onComplete,
}: CountdownProps) => {
  const ref = useRef(null);
  const children = (remainingTime: number) => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    switch (true) {
      case seconds === 0:
        return `${minutes}:${seconds}0`;
      case seconds <= 9:
        return `${minutes}:0${seconds}`;
      default:
        return `${minutes}:${seconds}`;
    }
  };

  return (
    <div
      className={clsx(styles.countdown, className, { [styles.expiredCountdown]: status === PaymentStatusType.Expired })}
      ref={ref}
    >
      <p className={styles.selector} />
      {description && <span className={styles.text}>{description}</span>}
      <CountdownCircleTimer
        isPlaying
        trailColor={status === PaymentStatusType.Expired ? '#F61717' : '#ffffff'}
        duration={duration}
        colors={['#5ac8fa', '#5ac8fa']}
        colorsTime={[duration, 0]}
        size={size}
        strokeWidth={strokeWidth}
        onComplete={onComplete}
      >
        {({ remainingTime }) => children(remainingTime)}
      </CountdownCircleTimer>
    </div>
  );
};
