import { filter, add } from 'lodash';
import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { axiosClient, url } from '@/api';
import { AxiosError } from 'axios';
import { CryptoCurrency, ErrorObject, FiatCurrency, PaymentType } from '../common.types';
import { getUniqueArrayValues } from '@/utils/functions';

export interface DepositWalletPayload {
  cryptoCurrency: CryptoCurrency;
  cryptoNetwork: CryptoCurrency;
}

export interface DepositWalletResponse {
  data: {
    address: null | string;
    memo: null | string;
  }[];
}

export interface BankDetailsPayload {
  paymentType: PaymentType;
}

export interface BankDetails {
  billerCode?: string;
  billerReference?: string;
  accountName?: string;
  bankAccountNr?: string;
  bsb?: string;
  description?: string;
  accountNo?: string;
  bic?: string;
  iban?: string;
  aba?: string;
}

export interface BankDetailsResponse {
  data: BankDetails[];
}

export function useGetDepositWallet<TSelectData = DepositWalletResponse, TError = AxiosError<ErrorObject>>(
  options: [
    DepositWalletPayload,
    Omit<UseQueryOptions<TSelectData, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  return useQuery<TSelectData, TError, TSelectData>(
    ['GetDepositWallet'],
    () => axiosClient.get(url.addressBookController.depositWallet(options[0].cryptoCurrency, options[0].cryptoNetwork)),
    {
      ...options[1],
      select: (data: any) => {
        return {
          data: getUniqueArrayValues(data.data),
        } as TSelectData;
      },
    },
  );
}

export function useGetBankDetails<TSelectData = BankDetailsResponse, TError = AxiosError<ErrorObject>>(
  options: [
    BankDetailsPayload,
    Omit<UseQueryOptions<TSelectData, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  return useQuery<TSelectData, TError, TSelectData>(
    ['GetBankDetails'],
    () => axiosClient.get(url.addressBookController.bankDetails(options[0].paymentType)),
    {
      ...options[1],
      select: (data: any) => {
        return {
          data: [...new Set(data.data.map((item: DepositWalletResponse['data']) => JSON.stringify(item)))].map((item) =>
            JSON.parse(item as string),
          ),
        } as TSelectData;
      },
    },
  );
}
