/* eslint-disable no-undef */
import { isBoolean } from 'lodash';
import { ReactNode } from 'react';

interface RouterSuspenseProps {
  children?: ReactNode;
  fallback?: JSX.Element | null;
  isReady?: boolean;
}

export const RouterSuspense = ({
  children,
  fallback = null,
  isReady,
}: RouterSuspenseProps) => {
  if (isBoolean(isReady) && !isReady) {
    return fallback;
  }

  return <>{children || fallback}</>;
};

export default RouterSuspense;
