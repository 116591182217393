import clsx from 'clsx';
import Image from 'next/image';
import merkleScience from '../../../public/assets/MerkleScience.png';
import styles from './MonitoredBy.module.css';

interface MonitoredByProps {
  className?: string;
  descriptionClassName?: string;
}

export const MonitoredBy = ({
  className,
  descriptionClassName,
}: MonitoredByProps) => {
  return (
    <div className={clsx(styles.container, className)}>
      <Image
        src={merkleScience}
        width={74}
        height={32}
        alt="Merkle Science"
        priority
        loading="eager"
      />
      <p className={clsx(styles.description, descriptionClassName)}>
        This transaction is monitored by Merkle Science a global leader in
        crypto compliance solutions.
      </p>
    </div>
  );
};
