import { useReadLocalStorage } from 'usehooks-ts';
import { AuthUserResponse } from '@/api';
import { USER_DETAILS } from '@/constants/storage';
import { Roles } from '@/constants/roles';

export const useRole = () => {
  const userDetails = useReadLocalStorage<AuthUserResponse>(USER_DETAILS);

  return userDetails?.userType === 'BUSINESS' ? Roles.BUSINESS : Roles.PERSONAL;
};
