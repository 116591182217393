import React, { useContext, useRef } from 'react';
import { useCheckboxGroupItem } from '@react-aria/checkbox';
import { AriaCheckboxGroupItemProps } from '@react-types/checkbox';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { CheckboxGroupContext } from './CheckboxGroup';
import CheckboxContent, { CheckboxCoreProps } from './CheckboxContent';

export type CheckboxGroupItemProps = AriaCheckboxGroupItemProps &
  CheckboxCoreProps;

export const CheckboxGroupItem = ({
  className,
  size = 'medium',
  ...props
}: CheckboxGroupItemProps) => {
  const { children } = props;
  const ref = useRef<HTMLInputElement>(null);
  const state = useContext(CheckboxGroupContext);
  const { inputProps } = useCheckboxGroupItem(
    {
      ...props,
      onKeyUp: (e) => {
        if (e.key === 'Enter') {
          state.toggleValue(props.value);
        }
        if (props.onKeyUp) props.onKeyUp(e);
      },
    },
    state,
    ref,
  );
  const { focusProps, isFocusVisible } = useFocusRing();

  const isDisabled = state.isDisabled || props.isDisabled;
  const isSelected = state.isSelected(props.value);

  return (
    <CheckboxContent
      className={className}
      isDisabled={isDisabled}
      isFocused={isFocusVisible}
      isSelected={isSelected}
      props={mergeProps(inputProps, focusProps)}
      size={size}
      ref={ref}
    >
      {children}
    </CheckboxContent>
  );
};

export default CheckboxGroupItem;
