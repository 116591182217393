import { useCheckStatus } from '@/api/queryHooks/useOnfidoController';
import { OnfidoStatus } from '@/api/common.types';

export const useIsVerified = () => {
  const { data: onfidoStatus } = useCheckStatus({ refetchOnMount: true });
  return (
    onfidoStatus?.status === OnfidoStatus.CLEAR ||
    onfidoStatus?.status === OnfidoStatus.BIOMETRIC_REQUIRED ||
    onfidoStatus?.status === OnfidoStatus.SELL_ONLY
  );
};
