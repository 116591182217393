import { ToggleState } from '@react-stately/toggle';
import { AriaRadioProps } from '@react-types/radio';
import { RadioGroupState } from '@react-stately/radio';
import { KeyboardEvents, KeyboardEvent } from '@react-types/shared';

export const onEnterPress = (props: KeyboardEvents, state: ToggleState) => {
  return (e: KeyboardEvent): void => {
    if (e.key === 'Enter') {
      state.toggle();
    }
    if (props.onKeyUp) props.onKeyUp(e);
  };
};

export const onEnterPressRadio = (
  props: KeyboardEvents,
  state: RadioGroupState,
  value: AriaRadioProps['value'],
) => {
  return (e: KeyboardEvent): void => {
    if (e.key === 'Enter' && value) {
      state.setSelectedValue(value);
    }
    if (props.onKeyUp) props.onKeyUp(e);
  };
};
