import { useGetAddress } from '@/api/queryHooks/useUserController';

const useIsAUSResident = () => {
  const { data: addressData } = useGetAddress({
    refetchOnMount: true,
  });
  return {
    isAUSResident:
      addressData?.country === 'AUS' ||
      addressData?.country === 'AU' ||
      addressData?.country === null ||
      addressData?.country === undefined,
  };
};

export default useIsAUSResident;
