import { axiosClient, url } from '..';
import { AxiosError } from 'axios';
import { ErrorObject } from '../common.types';
import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';

export interface WhereDidYouHearPayload {
  whereDidYouHearAboutUs: string;
}

export interface RatingResponse {
  lastRating: string;
  shouldAskForRating: boolean;
}

export function useWhereDidYouHear<TContext>(
  options?: Omit<
    UseMutationOptions<void, AxiosError<ErrorObject>, WhereDidYouHearPayload, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<void, AxiosError<ErrorObject>, WhereDidYouHearPayload, TContext> {
  const key = ['WhereDidYouHear'];

  return useMutation(
    (body: WhereDidYouHearPayload) => {
      return axiosClient.post(url.reviewController.whereDidYouHear, body);
    },
    {
      ...options,
      mutationKey: key,
    },
  );
}

export function useSetRating<TContext>(
  options?: Omit<UseMutationOptions<void, AxiosError<ErrorObject>, void, TContext>, 'mutationKey' | 'mutationFn'>,
): UseMutationResult<void, AxiosError<ErrorObject>, void, TContext> {
  const key = ['SetRating'];

  return useMutation(() => axiosClient.post(url.reviewController.rating), {
    ...options,
    mutationKey: key,
  });
}

export function useGetRating<TSelectData = RatingResponse, TError = AxiosError<ErrorObject>>(
  options?: Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['GetRating'],
    () => axiosClient.get(url.reviewController.getRating),
    options,
  );
}
