import clsx from 'clsx';
import { Spinner } from '@/components/common/Spinner';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import loader from '../../../public/animations/payment-loader.json';
import styles from './FindingPricesLoader.module.css';

interface FindingPricesLoaderProps {
  className?: string;
}

export const FindingPricesLoader = ({ className }: FindingPricesLoaderProps) => {
  const { isDesktop } = useMediaQuery();

  return (
    <div className={clsx(styles.loader, className)}>
      <Spinner animationData={loader} side={isDesktop ? 186 : 140} />

      <p className={styles.loaderTitle}>Finding best prices</p>
      <p className={styles.loaderDescription}>Please wait while we search the exchanges for the best rate</p>
    </div>
  );
};
