import React, { useMemo } from 'react';
import Pagination from 'rc-pagination';
import styles from './Pagination.module.css';
import { Icon } from '../Icon';

export const PAGINATION_TESTID = 'pagination';

export interface PaginationProps {
  totalElements?: number;
  pageSize?: number;
  currentPage: number;
  typeOfData?: string;
  updatePage: (value: number) => void;
}

export const PaginationComponent = ({
  pageSize = 8,
  currentPage,
  typeOfData = 'transactions',
  updatePage,
  totalElements = 0,
}: PaginationProps) => {
  const paginationInfo = useMemo(() => {
    const rangeMin = currentPage * pageSize - (pageSize - 1);
    let rangeMax = currentPage * pageSize;
    if (rangeMax > totalElements) {
      rangeMax = totalElements;
    }

    if (totalElements === 0) {
      return null;
    }

    return `${rangeMin}-${rangeMax} out of ${totalElements} ${typeOfData}`;
  }, [currentPage, pageSize, totalElements, typeOfData]);

  return (
    <div className={styles.paginationWrapper}>
      <Pagination
        data-testid={PAGINATION_TESTID}
        total={totalElements}
        pageSize={pageSize}
        current={currentPage}
        className={styles.pagination}
        onChange={updatePage}
        prevIcon={<Icon name="chevronLeft" />}
        nextIcon={<Icon name="chevronRight" />}
        jumpNextIcon={<Icon name="moreHorizontal" />}
        jumpPrevIcon={<Icon name="moreHorizontal" />}
      />
      <div className={styles.paginationInfo}>{paginationInfo}</div>
    </div>
  );
};
