import clsx from 'clsx';
import { useRef } from 'react';
import styles from './SummaryCard.module.css';

export interface SummaryCardProps {
  variants?: 'noFee' | 'fee' | 'payout' | 'processing';
  price?: string;
  total?: string;
  gatewayFee?: string;
  payout?: string;
  merchant?: string;
  amount?: string;
  currency?: string;
  cryptoValue?: string;
  cryptoCurrency?: string;
}

export const SummaryCard = ({
  variants,
  price,
  total,
  gatewayFee,
  payout,
  merchant,
  amount,
  currency,
  cryptoValue,
  cryptoCurrency,
}: SummaryCardProps) => {
  const ref = useRef(null);

  if (variants !== 'processing') {
    return (
      <div className={styles.card} ref={ref}>
        <span className={styles.title}>Summary</span>
        <div className={styles.row}>
          <span className={styles.text}>ETH Price</span>
          <span className={clsx(styles.text, styles.textValues)}>
            {price} {currency}
          </span>
        </div>
        {variants === 'fee' && (
          <div className={styles.row}>
            <span className={styles.text}>Gateway Fee</span>
            <span className={clsx(styles.text, styles.textValues)}>
              {gatewayFee}%
            </span>
          </div>
        )}
        {variants === 'payout' && (
          <>
            <div className={styles.row}>
              <span className={styles.text}>Payout</span>
              <span className={clsx(styles.text, styles.textValues)}>
                {payout}
              </span>
            </div>
            <div className={styles.row}>
              <span className={styles.text}>Gateway Fee</span>
              <span className={clsx(styles.text, styles.textValues)}>
                {gatewayFee}%
              </span>
            </div>
          </>
        )}
        <div className={styles.row}>
          <span className={styles.text}>Total</span>
          <span className={clsx(styles.text, styles.textValues)}>
            {total} {currency}
          </span>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.card} ref={ref}>
      <span className={styles.title}>Summary</span>
      <div className={styles.row}>
        <span className={styles.text}>Merchant</span>
        <span className={clsx(styles.text, styles.textValues)}>{merchant}</span>
      </div>
      <div className={styles.row}>
        <span className={styles.text}>Amount</span>
        <span className={clsx(styles.text, styles.textValues)}>
          {amount} {currency}
        </span>
      </div>
      <div className={styles.row}>
        <span className={styles.text}>Crypto</span>
        <span className={clsx(styles.text, styles.textValues)}>
          {cryptoValue} {cryptoCurrency}
        </span>
      </div>
    </div>
  );
};
