import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import clsx from 'clsx';
import styles from './Checkbox.module.css';
import { Icon } from '../../Icon';

export interface CheckboxCoreProps {
  className?: string;
  size?: 'medium' | 'large';
}

interface CheckboxContentProps extends CheckboxCoreProps {
  props: InputHTMLAttributes<HTMLInputElement>;
  isSelected?: boolean;
  isFocused?: boolean;
  isDisabled?: boolean;
  children?: ReactNode;
}

const CheckboxContent = forwardRef<HTMLInputElement, CheckboxContentProps>(
  (
    {
      props,
      children,
      size = 'medium',
      className,
      isDisabled,
      isFocused,
      isSelected,
    },
    ref,
  ) => {
    return (
      <label
        className={clsx(styles.container, className, {
          [styles.disabled]: isDisabled,
        })}
      >
        <VisuallyHidden>
          <input
            {...props}
            className={styles.input}
            ref={ref}
            type="checkbox"
          />
        </VisuallyHidden>
        <span
          className={clsx(styles.checkbox, {
            [styles.checkboxLarge]: size === 'large',
            [styles.checked]: isSelected,
            [styles.focused]: isFocused,
            [styles.disabled]: isDisabled,
          })}
        >
          <Icon
            className={styles.icon}
            name="check"
            size={size === 'medium' ? 15 : 16}
          />
        </span>
        {!!children && <span className={styles.label}>{children}</span>}
      </label>
    );
  },
);

CheckboxContent.displayName = 'CheckboxContent';

export default CheckboxContent;
