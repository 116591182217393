import clsx from 'clsx';
import React, { useContext, useRef } from 'react';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useRadio, useRadioGroup } from '@react-aria/radio';
import { useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { useRadioGroupState } from '@react-stately/radio';
import { onEnterPressRadio } from '../../../../utils/events';
import styles from './RadioSelectAccount.module.css';
import { RadioContext, RadioGroupProps, RadioProps } from '../Radio';

export const RadioSelectAccountGroup = ({
  className,
  ariaLabel,
  ...props
}: RadioGroupProps) => {
  const { children } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps } = useRadioGroup(
    { ...props, 'aria-label': ariaLabel },
    state,
  );

  return (
    <div {...radioGroupProps} className={clsx(styles.group, className)}>
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
    </div>
  );
};

export interface RadioSelectAccountProps {
  title?: string;
  description?: string;
}

export const RadioSelectAccount = ({
  className,
  contentClassName,
  title,
  description,
  ...props
}: RadioProps & RadioSelectAccountProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const state = useContext(RadioContext);
  const { inputProps } = useRadio(
    {
      ...props,
      onKeyUp: onEnterPressRadio(props, state, props.value),
    },
    state,
    ref,
  );
  const { focusProps, isFocusVisible: isFocused } = useFocusRing();
  const isChecked = !!inputProps.checked;
  return (
    <label
      className={clsx(styles.wrapper, className, {
        [styles.wrapperChecked]: isChecked,
      })}
    >
      <VisuallyHidden>
        <input
          {...mergeProps(inputProps, focusProps)}
          className={styles.input}
          ref={ref}
          type="radio"
        />
      </VisuallyHidden>
      <div className={clsx(styles.labelWrapper, contentClassName)}>
        <span className={styles.title}>{title}</span>
        <p className={styles.description}>{description}</p>
      </div>
      <div
        className={clsx(styles.radio, {
          [styles.checked]: isChecked,
          [styles.focused]: isFocused,
        })}
      >
        {isChecked && (
          <span
            className={clsx(styles.radioDot, {
              [styles.checkedRadioDot]: isChecked,
              [styles.focused]: isFocused,
            })}
          />
        )}
      </div>
    </label>
  );
};
