import { useState } from 'react';
import { QRCode } from 'react-qrcode-logo';

interface QrCodeProps {
  value: string;
  codeSize: number;
  logoSize?: number;
  logoSrc?: string;
  quietZone: number;
  onClick?: () => void;
}

export const QrCode = ({ value, codeSize, logoSize, logoSrc, quietZone, onClick }: QrCodeProps) => {
  const [state, setState] = useState<{ [key: string]: any }>({});
  return (
    <div
      style={{
        borderRadius: 16,
        width: codeSize + quietZone * 2 + 2,
        border: '1px solid #f6f6f6',
        overflow: 'hidden',
        boxShadow: '0px 0px 32px rgba(13, 8, 86, 0.04)',
      }}
      onClick={onClick}
    >
      <QRCode
        value={value}
        size={codeSize}
        quietZone={quietZone}
        bgColor="#fdfefe"
        fgColor="#0D0856"
        logoWidth={logoSize}
        logoImage={logoSrc}
        removeQrCodeBehindLogo
        {...{
          ...state,
          eyeRadius: [
            {
              outer: [
                state.eyeradius_0_outer_0,
                state.eyeradius_0_outer_1,
                state.eyeradius_0_outer_2,
                state.eyeradius_0_outer_3,
              ],
              inner: [
                state.eyeradius_0_inner_0,
                state.eyeradius_0_inner_1,
                state.eyeradius_0_inner_2,
                state.eyeradius_0_inner_3,
              ],
            },
            {
              outer: [
                state.eyeradius_1_outer_0,
                state.eyeradius_1_outer_1,
                state.eyeradius_1_outer_2,
                state.eyeradius_1_outer_3,
              ],
              inner: [
                state.eyeradius_1_inner_0,
                state.eyeradius_1_inner_1,
                state.eyeradius_1_inner_2,
                state.eyeradius_1_inner_3,
              ],
            },
            {
              outer: [
                state.eyeradius_2_outer_0,
                state.eyeradius_2_outer_1,
                state.eyeradius_2_outer_2,
                state.eyeradius_2_outer_3,
              ],
              inner: [
                state.eyeradius_2_inner_0,
                state.eyeradius_2_inner_1,
                state.eyeradius_2_inner_2,
                state.eyeradius_2_inner_3,
              ],
            },
          ],
          eyeColor: [
            {
              outer: state.eyecolor_0_outer ?? state.fgColor ?? '#0D0856',
              inner: state.eyecolor_0_inner ?? state.fgColor ?? '#0D0856',
            },
            {
              outer: state.eyecolor_1_outer ?? state.fgColor ?? '#0D0856',
              inner: state.eyecolor_1_inner ?? state.fgColor ?? '#0D0856',
            },
            {
              outer: state.eyecolor_2_outer ?? state.fgColor ?? '#0D0856',
              inner: state.eyecolor_2_inner ?? state.fgColor ?? '#0D0856',
            },
          ],
        }}
      />
    </div>
  );
};
