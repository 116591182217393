export enum Roles {
  PERSONAL = 'personal',
  BUSINESS = 'business',
}

export const roles: string[] = Object.values(Roles);

export type RoleType = 'business' | 'personal';

export enum RoleBasedRoutes {
  PERSONAL = '/personal',
  BUSINESS = '/business',
}

export const roleBasedRoutes: string[] = Object.values(RoleBasedRoutes);
