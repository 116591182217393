import React, { RefObject, useRef } from 'react';
import clsx from 'clsx';
import { useButton, AriaButtonProps, useLink, AriaLinkOptions } from 'react-aria';
import Link from 'next/link';
import styles from './Button.module.css';
import { Icon, IconType } from '../../Icon/Icon';

export interface ButtonProps extends AriaButtonProps<'button'> {
  className?: string;
  linkClassName?: string;
  variants?:
    | 'primary'
    | 'secondary'
    | 'secondaryGray'
    | 'subtle'
    | 'subtleWarning'
    | 'whiteBlue'
    | 'whiteGreen'
    | 'whiteCreator';
  additionalBg?: 'blue' | 'none';
  size?: 'large' | 'medium' | 'small' | 'mobile' | 'largeSubtle' | 'mediumSubtle' | 'smallSubtle';
  icon?: IconType;
  iconClassName?: string;
  href?: string;
  iconPosition?: 'left' | 'right';
  fluid?: boolean;
  navigationTab?: boolean;
  isActive?: boolean;
  buttonRef?: RefObject<HTMLButtonElement>;
  loading?: boolean;
  isExternal?: boolean;
}

export const Button = (props: ButtonProps) => {
  const ref = useRef(null);
  const {
    className,
    linkClassName,
    variants = 'primary',
    additionalBg = 'none',
    size = 'medium',
    icon,
    iconClassName,
    iconPosition = 'left',
    href,
    buttonRef = ref,
    children,
    fluid,
    navigationTab,
    isActive,
    loading,
    isExternal,
    ...otherProps
  } = props;
  const { buttonProps } = useButton(otherProps, buttonRef);
  const { linkProps } = useLink(otherProps as AriaLinkOptions, ref);
  const LinkComponent = isExternal ? 'a' : Link;

  const defaultStyles = [
    className,
    styles.button,
    styles[variants],
    styles[size],
    { [styles.fluid]: fluid },
    buttonProps.className,
    styles[additionalBg],
  ];

  switch (variants) {
    case 'subtle':
      return href ? (
        <LinkComponent
          href={String(href)}
          {...linkProps}
          {...(isExternal && {
            target: '_blank',
            rel: 'noreferrer noopener',
          })}
          className={clsx(linkClassName, styles.link, ...defaultStyles)}
          ref={ref}
        >
          <div className={styles.button}>
            {!!icon && (
              <div
                className={clsx(styles.icon, styles[iconPosition], styles.iconSubtleMedium, {
                  [styles.iconSubtleSmall]: size === 'smallSubtle',
                })}
              >
                <Icon
                  name={icon}
                  size={size === 'largeSubtle' ? 22 : 20}
                  className={clsx(styles.icon, styles[variants], iconClassName)}
                />
              </div>
            )}
            {children && <span className={styles.subtleText}>{children}</span>}
          </div>
        </LinkComponent>
      ) : (
        <button
          type="button"
          {...buttonProps}
          className={clsx(...defaultStyles, {
            [styles.navigationTab]: navigationTab,
          })}
          ref={buttonRef}
        >
          <div
            className={clsx(styles.button, styles.subtleInner, {
              [styles.active]: isActive,
            })}
          >
            {!!icon && (
              <div
                className={clsx(styles.icon, styles[iconPosition], styles.iconSubtleMedium, {
                  [styles.iconSubtleSmall]: size === 'smallSubtle',
                })}
              >
                <Icon
                  name={icon}
                  size={size === 'largeSubtle' ? 22 : 20}
                  className={clsx(styles.icon, styles[variants], iconClassName)}
                />
              </div>
            )}
            {children && <span className={styles.subtleText}>{children}</span>}
          </div>
        </button>
      );
    default:
      return href ? (
        <LinkComponent
          href={String(href)}
          {...linkProps}
          {...(isExternal && {
            target: '_blank',
            rel: 'noreferrer noopener',
          })}
          className={clsx(linkClassName, styles.link, ...defaultStyles)}
          ref={ref}
        >
          <button type="button" {...buttonProps} ref={buttonRef}>
            <div className={styles.button}>
              {!!icon && (
                <div
                  className={clsx(styles.icon, styles[iconPosition], styles.iconMedium, {
                    [styles.iconSmall]: size === 'small',
                  })}
                >
                  <Icon
                    name={icon}
                    size={size === 'large' ? 22 : 20}
                    className={clsx(styles.icon, styles[variants], iconClassName)}
                  />
                </div>
              )}
              <span className={styles.text}>{children}</span>
            </div>
          </button>
        </LinkComponent>
      ) : (
        <button
          type="button"
          {...buttonProps}
          className={clsx(...defaultStyles, { [styles.loading]: loading })}
          ref={buttonRef}
        >
          {loading && (
            <svg className={styles.loader} focusable="false" viewBox="0 0 20 20">
              <circle cx="10" cy="10" r="8" />
            </svg>
          )}
          <div className={styles.button}>
            {!!icon && (
              <div
                className={clsx(styles.icon, styles[iconPosition], styles.iconMedium, {
                  [styles.iconSmall]: size === 'small',
                })}
              >
                <Icon
                  name={icon}
                  size={size === 'large' ? 22 : 20}
                  className={clsx(styles.icon, styles[variants], iconClassName)}
                />
              </div>
            )}
            <span className={styles.text}>{children}</span>
          </div>
        </button>
      );
  }
};
