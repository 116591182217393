import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from 'react-query';
import { axiosClient, url } from '@/api';
import { AxiosError } from 'axios';
import { AgeGroup, ErrorObject, OnfidoStatus } from '../common.types';

export interface OnfidoCountry {
  iso2: string;
  iso3: string;
  callingCode: string;
  name: string;
}

export interface SdkTokenPayload {
  verificationType: 'BIOMETRIC' | 'REGISTRATION';
}

export function useCheckStatus<
  TSelectData = {
    status: OnfidoStatus;
    ageGroup: AgeGroup;
  },
  TError = AxiosError<ErrorObject>,
>(
  options?: Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['CheckStatus'],
    () => axiosClient.get(url.onfidoController.checkStatus),
    options,
  );
}

export function useCheckAddress<TContext>(
  options?: Omit<
    UseMutationOptions<{ addressValid: boolean }, AxiosError<ErrorObject>, void, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<{ addressValid: boolean }, AxiosError<ErrorObject>, void, TContext> {
  const key = ['CheckAddress'];

  return useMutation(() => axiosClient.get(url.onfidoController.checkAddress), {
    ...options,
    mutationKey: key,
  });
}

export function useGetSdkToken<TContext>(
  options?: Omit<
    UseMutationOptions<{ token: string }, AxiosError<ErrorObject>, SdkTokenPayload, TContext>,
    'mutationKey' | 'mutationFn'
  >,
): UseMutationResult<{ token: string }, AxiosError<ErrorObject>, SdkTokenPayload, TContext> {
  const key = ['GetSdkToken'];

  return useMutation(
    (body: SdkTokenPayload) =>
      axiosClient.post(url.onfidoController.getSdkToken, body, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      }),
    {
      ...options,
      mutationKey: key,
    },
  );
}

export function useSubmitCheck<TSelectData, TError = AxiosError<ErrorObject>>(
  options?: [
    { type: SdkTokenPayload['verificationType'] },
    Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['SubmitCheck'],
    () => axiosClient.get(url.onfidoController.submitCheckStandard(options?.[0]?.type)),
    options?.[1],
  );
}

export function useGetCountries<TSelectData = OnfidoCountry[], TError = AxiosError<ErrorObject>>(
  options?: Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['GetCountries'],
    () => axiosClient.get(url.onfidoController.getCountries),
    options,
  );
}
