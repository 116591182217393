import { useRef } from 'react';
import clsx from 'clsx';
import styles from './Label.module.css';
import { Icon, IconType } from '../Icon';

export const LABEL_TESTID = 'label';
export const LABEL_LINK_TESTID = 'label-link';

type LabeSizeType = 'small' | 'medium' | 'big';

export type LabelColor = 'red' | 'green' | 'blue' | 'gray' | 'yellow' | 'tangerine';
export interface LabelProps {
  children: string;
  color: LabelColor;
  size?: LabeSizeType;
  icon?: IconType;
  iconPosition?: 'left' | 'right';
  className?: string;
  href?: string;
}

const ICON_SIZES: Record<LabeSizeType, number> = {
  small: 10,
  medium: 12,
  big: 12,
};

export const Label = ({
  size = 'medium',
  children,
  color = 'red',
  icon,
  iconPosition = 'left',
  className,
  href,
}: LabelProps) => {
  const ref = useRef(null);

  const commonProps = {
    className: clsx(styles.label, styles[size], styles[color], className),
    ref,
  };

  const renderCommonContent = () => {
    return (
      <>
        {!!icon && (
          <div className={clsx(styles.icon, styles[iconPosition])}>
            <Icon name={icon} size={ICON_SIZES[size]} />
          </div>
        )}
        <span className={clsx(styles.text, { underline: !!href })} dangerouslySetInnerHTML={{ __html: children }} />
      </>
    );
  };

  if (href) {
    return (
      <a data-testid={LABEL_LINK_TESTID} {...commonProps} href={href}>
        {renderCommonContent()}
      </a>
    );
  }

  return (
    <div data-testid={LABEL_TESTID} {...commonProps}>
      {renderCommonContent()}
    </div>
  );
};
