/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import * as React from 'react';
import type { AriaListBoxOptions } from '@react-aria/listbox';
import clsx from 'clsx';
import type { ListState } from 'react-stately';
import type { Node } from '@react-types/shared';
import { useListBox, useListBoxSection, useOption } from 'react-aria';
import styles from './SelectList.module.css';

export { Item as SelectListItem } from '@react-stately/collections';

interface ListBoxProps extends AriaListBoxOptions<unknown> {
  listBoxRef?: React.RefObject<HTMLUListElement>;
  state: ListState<unknown>;
  className?: string;
  popupClassName?: string;
  isShowMore?: boolean;
  showMoreNumber?: number;
}

interface SectionProps {
  section: Node<unknown>;
  state: ListState<unknown>;
  maxHeightScroll?: number;
}

interface OptionProps {
  item: Node<unknown>;
  state: ListState<unknown>;
}

export function ListBox(props: ListBoxProps) {
  const { isShowMore, showMoreNumber = 4 } = props;
  const ref = React.useRef<HTMLUListElement>(null);
  const { listBoxRef = ref, state, popupClassName } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  const [isShowMoreActive, setIsShowMoreActive] = React.useState(isShowMore);

  return (
    <ul
      {...listBoxProps}
      ref={listBoxRef}
      className={clsx(styles.listBox, 'w-full max-h-72 overflow-auto outline-none', popupClassName)}
    >
      {isShowMoreActive && showMoreNumber <= [...state.collection].length ? (
        <>
          {[...state.collection]
            .slice(0, showMoreNumber)
            .map((item) =>
              item.type === 'section' ? (
                <ListBoxSection key={item.key} section={item} state={state} />
              ) : (
                <Option key={item.key} item={item} state={state} />
              ),
            )}
          <button
            className="text-xs font-medium uppercase text-primary-1 mx-3 cursor-pointer py-3"
            onClick={(e) => {
              e.preventDefault();
              setIsShowMoreActive(false);
            }}
          >
            Show More
          </button>
        </>
      ) : (
        [...state.collection].map((item) =>
          item.type === 'section' ? (
            <ListBoxSection key={item.key} section={item} state={state} />
          ) : (
            <Option key={item.key} item={item} state={state} />
          ),
        )
      )}
    </ul>
  );
}

function ListBoxSection({ section, state, maxHeightScroll }: SectionProps) {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  return (
    <>
      <li {...itemProps} className="pt-2">
        {section.rendered && (
          <span {...headingProps} className="text-xs font-bold uppercase text-gray-500 mx-3">
            {section.rendered}
          </span>
        )}
        <ul
          {...groupProps}
          style={{ maxHeight: maxHeightScroll }}
          className={clsx(styles.listContainer, {
            [styles.isScrollable]: maxHeightScroll,
          })}
        >
          {[...section.childNodes].map((node) => (
            <Option key={node.key} item={node} state={state} />
          ))}
        </ul>
      </li>
    </>
  );
}

function Option({ item, state }: OptionProps) {
  const ref = React.useRef<HTMLLIElement>(null);
  const { optionProps, isSelected } = useOption(
    {
      key: item.key,
    },
    state,
    ref,
  );

  return (
    <li {...optionProps} ref={ref} className={styles.option}>
      {/* <Image /> */}
      {item.rendered}
      <div
        className={clsx(styles.radio, {
          [styles.checked]: isSelected,
        })}
      >
        {isSelected && (
          <span
            className={clsx(styles.radioDot, {
              [styles.checkedRadioDot]: isSelected,
            })}
          />
        )}
      </div>
    </li>
  );
}
