/* eslint-disable no-undef */
import clsx from 'clsx';
import map from 'lodash/map';
import { Country } from 'react-phone-number-input';
import { DEFAULT_COUNTRY_CODE_3 } from '@/constants/default';
import { ItemType } from '@/components/common/form/SelectRC/SelectRCOption';
import { useMemo } from 'react';
import reduce from 'lodash/reduce';
import { FIAT_CURRENCY_SUPPORTED_ON_BE } from '@/constants/common';
import { keccak } from 'ethereumjs-util';
import { FiatCurrency } from '@/api/common.types';
import { Select } from '../Select/Select';
import { SelectListItem } from '../SelectList';
import styles from './SelectCurrencyCountry.module.css';
import { useSelectedCurrency } from './useSelectedCurrency';

interface CurrencyCodeWithFlagInterface {
  currency: string;
  flag: JSX.Element | undefined;
  countryCode: Country;
}

interface SelectCurrencyCountryProps {
  label?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  value: string;
  className?: string;
  isDisabled?: boolean;
}

const currencyCodesWithFlagsSupportedOnBE = (currencyCodesWithFlags: CurrencyCodeWithFlagInterface[]) => {
  return currencyCodesWithFlags.filter(({ currency }) =>
    Object.prototype.hasOwnProperty.call(FIAT_CURRENCY_SUPPORTED_ON_BE, currency),
  );
};

export const SelectCurrencyCountry = ({
  label,
  onChange,
  value,
  placeholder,
  className,
  isDisabled,
}: SelectCurrencyCountryProps) => {
  const { selectedCurrency, currencyCodesWithFlags } = useSelectedCurrency(value);

  const renderItem = ({ currency, flag }: Omit<CurrencyCodeWithFlagInterface, 'countryCode'>) => (
    <div className={styles.row}>
      <div className={styles.flag}>{flag}</div>
      {currency}
    </div>
  );

  return (
    <Select
      className={className}
      onSelectionChange={(v) => {
        onChange?.(v as string);
      }}
      isDisabled={isDisabled}
      label={label}
      placeholder={placeholder}
      inputValue={value}
      defaultInputValue={DEFAULT_COUNTRY_CODE_3}
      defaultFlag={DEFAULT_COUNTRY_CODE_3}
      flag={value && <div className={clsx(styles.flag, styles.input)}>{selectedCurrency?.flag}</div>}
    >
      {map(currencyCodesWithFlagsSupportedOnBE(currencyCodesWithFlags), ({ currency, flag }) => {
        return (
          <SelectListItem key={currency} textValue={currency}>
            {renderItem({ currency, flag })}
          </SelectListItem>
        );
      })}
    </Select>
  );
};
