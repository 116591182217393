import axios from 'axios';
import { ACCESS_TOKEN, DEVICE_TOKEN } from '@/constants/storage';
import { Routes } from '@/constants/routes';
import { PRIVATE_URLS } from './url';
import { v4 as uuidv4 } from 'uuid';
import { datadogRum } from '@datadog/browser-rum';
import packageInfos from '../package.json';
import { datadogLogs } from '@datadog/browser-logs';

// here is the list of endpoints
const endpointsNotTriggeredLogout = [
  '/oauth/token',
  '/login/mfa/sms',
  '/private/user/password',
  '/user/register',
  '/oauth/get-code',
  '/merchant/txs',
  '/private/merchant/capabilities',
  '/private/merchant/settlement-information',
  '/private/merchant/hosted/url',
];

export const axiosClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL + '/v1',
});

axiosClient.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem(ACCESS_TOKEN)?.replaceAll('"', '');
    const isPrivateUrl = PRIVATE_URLS.some((url) => config?.url?.startsWith?.(url));

    if (accessToken && accessToken !== 'null' && isPrivateUrl) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      delete config.headers.Authorization;
    }
    config.headers['x-trace-id'] = uuidv4();
    config.headers['x-trace-user-id'] = datadogRum.getUser()?.id;
    config.headers['x-app-version'] = process.env.NEXT_PUBLIC_APP_VERSION || packageInfos.version;
    config.headers['x-source'] = packageInfos.name;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosClient.interceptors.response.use(
  (response) => {
    if (response.config.url === '/login/oauth/verify-code' && response.data?.deviceToken) {
      localStorage.setItem(DEVICE_TOKEN, response.data.deviceToken);
    }
    if (response.data.accessToken) {
      localStorage.setItem(ACCESS_TOKEN, `"${response.data.accessToken}"`);
    }
    return response.data;
  },
  (error) => {
    const errorData = {
      status: error?.request?.status,
      message: error?.message,
      url: error?.config?.url,
      method: error?.config?.method,
      requestData: error?.config?.data,
      responseData: error?.response?.data,
    };

    datadogLogs.logger.error(`WebAppError: ${error?.message}`, {
      errorData,
    });

    if (
      error?.request?.status === 401 &&
      !error?.config?._retry &&
      !endpointsNotTriggeredLogout.includes(error?.config?.url)
    ) {
      error.config._retry = true;
      localStorage.removeItem(ACCESS_TOKEN);
      window.location.href = Routes.SIGN_IN;
    }
    return Promise.reject(error);
  },
);
