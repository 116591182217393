import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { axiosClient, url } from '@/api';
import { AxiosError } from 'axios';
import { ErrorObject, PaymentType } from '../common.types';

interface useGetPriceResponse {
  base: string;
  baseAmount: number;
}

interface useGetPricePayload {
  crypto: string;
  fiat: string;
  amount: string;
  parent?: string;
}

export function useGetPrice<TSelectData = useGetPriceResponse, TError = AxiosError<ErrorObject>>(
  options: [
    { crypto: useGetPricePayload['crypto']; fiat: useGetPricePayload['fiat']; amount: useGetPricePayload['amount']; parent?: useGetPricePayload['parent'] },
    Omit<UseQueryOptions<TSelectData, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  return useQuery<TSelectData, TError, TSelectData>(
    ['GetPrice'],
    () => axiosClient.get(url.priceController.getPrices(options[0].fiat, options[0].crypto, options[0].amount, options[0].parent)),
    {
      ...options[1],
    },
  );
}
