import clsx from 'clsx';
import { map } from 'lodash';
import { forwardRef, useState } from 'react';
import {
  getCountries,
  getCountryCallingCode,
  Country,
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { DEFAULT_COUNTRY_CODE_2 } from '@/constants/default';
import { Select } from '../Select/Select';
import { SelectListItem } from '../SelectList';
import styles from './SelectPhoneCountry.module.css';

const renderFlag = (country: Country) =>
  flags?.[country || DEFAULT_COUNTRY_CODE_2]?.({
    title: country || DEFAULT_COUNTRY_CODE_2,
  });
const getPhoneCodeByCountryCode = (country: Country) => `+${getCountryCallingCode(country || DEFAULT_COUNTRY_CODE_2)}`;

interface SelectPhoneCountryProps {
  label?: string;
  placeholder?: string;
  onChange?: (v: string) => void;
  className?: string;
  value?: string;
}

export const SelectPhoneCountry = forwardRef<HTMLInputElement, SelectPhoneCountryProps>(
  ({ label, onChange, placeholder, value, className }, ref) => {
    const [countryCode, setCountryCode] = useState<Country>();
    const renderItem = (country: Country) => {
      return (
        <div className={styles.row}>
          <div className={styles.flag}>{renderFlag(country)}</div>
          {`${getPhoneCodeByCountryCode(country)} ${country}`}
        </div>
      );
    };

    const formatedValue = formatPhoneNumberIntl(value || '') || value;

    const handleInputChange = (v: string) => {
      const { number } = parsePhoneNumber(v, countryCode) || {};
      if (onChange) onChange(number || v);
    };

    return (
      <Select
        className={className}
        onSelectionChange={(v) => {
          if (v) setCountryCode(v as Country);
        }}
        label={label}
        placeholder={placeholder}
        defaultInputValue={getPhoneCodeByCountryCode(DEFAULT_COUNTRY_CODE_2)}
        defaultFlag={DEFAULT_COUNTRY_CODE_2}
        onInputChange={handleInputChange}
        allowsCustomValue
        inputValue={formatedValue}
        flag={
          countryCode && <div className={clsx(styles.flag, styles.input)}>{renderFlag(countryCode as Country)}</div>
        }
      >
        {map(getCountries(), (country) => (
          <SelectListItem key={country} textValue={getPhoneCodeByCountryCode(country)} title={renderItem(country)}>
            {renderItem(country)}
          </SelectListItem>
        ))}
      </Select>
    );
  },
);

SelectPhoneCountry.displayName = 'SelectPhoneCountry';
