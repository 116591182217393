import React, { FC, useRef } from 'react';
import { usePreventScroll, useModal, OverlayProps, OverlayContainer, AriaOverlayProps } from '@react-aria/overlays';
import { useOverlay } from 'react-aria';
import clsx from 'clsx';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import { AriaDialogProps } from '@react-types/dialog';
import { mergeProps } from '@react-aria/utils';
import styles from './Popup.module.css';
import { Button, CloseButton } from '../buttons';

export interface PopupProps extends AriaDialogProps, Omit<AriaOverlayProps, 'isKeyboardDismissDisabled'>, OverlayProps {
  overlayClassName?: string;
  underlayClassName?: string;
  dialogClassName?: string;
  hasCloseIcon?: boolean;
  title?: string;
  cancelButtonText?: string;
  successButtonText?: string;
  successButtonSize?: 'small' | 'medium' | 'large';
  successButtonClass?: string;
  onSuccess?: () => void;
  isLoadingSuccessBtn?: boolean;
  isDisabledSuccessBtn?: boolean;
  footerContent?: React.ReactNode;
}

export const PopupCore: FC<PopupProps> = ({
  overlayClassName,
  underlayClassName,
  dialogClassName,
  hasCloseIcon,
  title,
  cancelButtonText,
  successButtonText,
  successButtonSize,
  successButtonClass,
  onSuccess,
  isLoadingSuccessBtn,
  isDisabledSuccessBtn,
  footerContent,
  ...props
}) => {
  const { children } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(props, ref);
  usePreventScroll();
  const { modalProps } = useModal();
  const { dialogProps } = useDialog(props, ref);

  return (
    <OverlayContainer className={clsx(styles.overlay, overlayClassName)}>
      <div {...underlayProps} className={clsx(styles.underlay, underlayClassName)}>
        <FocusScope contain restoreFocus>
          <div
            {...mergeProps(overlayProps, dialogProps, modalProps)}
            className={clsx(styles.dialog, dialogClassName, 'fade-in-zoom')}
            ref={ref}
          >
            {hasCloseIcon && (
              <CloseButton
                className={styles.closeButton}
                onPress={() => {
                  if (props.onClose) props.onClose();
                }}
              />
            )}
            {successButtonText ? (
              <>
                {children}
                <Button
                  loading={isLoadingSuccessBtn}
                  onPress={onSuccess}
                  className={successButtonClass}
                  size={successButtonSize}
                  isDisabled={isDisabledSuccessBtn}
                >
                  {successButtonText}
                </Button>
              </>
            ) : (
              children
            )}
            {footerContent && <div className={styles.footer}>{footerContent}</div>}
          </div>
        </FocusScope>
      </div>
    </OverlayContainer>
  );
};

export const Popup: FC<PopupProps> = (props) => {
  if (props.isOpen) {
    return (
      <PopupCore {...props} underlayClassName={props.underlayClassName}>
        {props.children}
      </PopupCore>
    );
  }
  return null;
};

export default Popup;
