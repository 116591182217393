import React, { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import { add, map, reverse, size, slice, subtract } from 'lodash';
import styles from './Steps.module.css';
import { COLOR_TYPES, GRADIENT_TYPES } from './Steps.utils';

interface StepsItemProps {
  className?: string;
  textClassName?: string;
  children: string;
  color: string;
}

const StepsItem = ({
  className,
  textClassName,
  children,
  color,
}: StepsItemProps) => {
  return (
    <div
      style={{ borderColor: color }}
      className={clsx(styles.item, className)}
    >
      <span style={{ color }} className={clsx(styles.text, textClassName)}>
        {children}
      </span>
    </div>
  );
};

interface StepsLineProps {
  className?: string;
  gradient: string;
  isHosted?: boolean;
  isSell?: boolean;
}

const StepsLine = ({
  className,
  gradient,
  isHosted,
  isSell,
}: StepsLineProps) => {
  return (
    <div
      style={{ background: gradient }}
      className={clsx(styles.line, className, {
        [styles.hostedLine]: isHosted,
        [styles.sellLine]: isSell,
      })}
    ></div>
  );
};

export interface StepsProps {
  className?: string;
  textClassName?: string;
  options: string[];
  active: number;
  isHosted?: boolean;
  isSell?: boolean;
}

const INDEX_COMPENSATION = 1;
const LIST_COMPENSATION = 1;
const MAX_COUNT = 4;

export const Steps = ({
  className,
  textClassName,
  options,
  active,
  isHosted,
  isSell,
}: StepsProps) => {
  const safeActive = useMemo(() => {
    if (active < 1) return 1;
    if (active > size(options)) return size(options);
    return active;
  }, [active, options]);

  const countCompensation =
    MAX_COUNT - size(options) + safeActive - INDEX_COMPENSATION;

  const gradients = slice(
    GRADIENT_TYPES,
    -(size(options) + countCompensation - LIST_COMPENSATION),
  );
  const colors = slice(COLOR_TYPES, -(size(options) + countCompensation));

  return (
    <div className={clsx(styles.list, className)}>
      {map(options, (option, idx) => {
        const order = add(idx, INDEX_COMPENSATION);

        return (
          <Fragment key={option}>
            <StepsItem
              color={colors[idx]}
              className={clsx(styles.listItem)}
              textClassName={textClassName}
            >
              {option}
            </StepsItem>
            {order < size(options) && (
              <StepsLine
                isHosted={isHosted}
                isSell={isSell}
                className={clsx(styles.listLine)}
                gradient={gradients[idx]}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
