import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { isNumber } from 'lodash';
import styles from './ContainerCard.module.css';

export interface ContainerCardProps {
  className?: string;
  children?: ReactNode;
  width?: number | string;
  centered?: boolean;
  customStyles?: React.CSSProperties;
}

export const ContainerCard: FC<ContainerCardProps> = ({
  className,
  children,
  width = 480,
  centered = true,
  customStyles,
}) => {
  return (
    <div
      style={{ maxWidth: isNumber(width) ? `${width}px` : width, ...customStyles }}
      className={clsx(styles.container, { [styles.centered]: centered }, className)}
    >
      {children}
    </div>
  );
};
