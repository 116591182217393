import clsx from 'clsx';
import React, { useCallback, useState, ReactNode } from 'react';
import { Rifm } from 'rifm';
import { map, toUpper } from 'lodash';
import { ItemTypeSelect, SelectRC } from '../SelectRC';
import styles from './SelectRCCurrencyCoins.module.css';
import { ItemType } from '../SelectRC/SelectRCOption';

export interface SelectRCCurrencyCoinsProps {
  className?: string;
  label?: string;
  size?: 'medium' | 'large';
  labelClassName?: string;
  isHosted?: boolean;
  onChange: (v: string) => void;
  value: string;
  placeholder?: string;
  withoutRadios?: boolean;
  coinsData: ItemType[];
  onSelectChange: (v: ItemTypeSelect) => void;
  selectValue: ItemTypeSelect | undefined;
  isBuy?: boolean;
  hasValueName?: boolean;
  menuClassName?: string;
  menuPosition?: 'fixed' | 'absolute';
  isWidget?: boolean;
  blurMenuEnabled?: boolean;
  themeColor?: string;
  borderRadius?: string;
}
export const SelectRCCurrencyCoins = ({
  onChange,
  value,
  className,
  label,
  size = 'large',
  labelClassName,
  isHosted,
  placeholder,
  withoutRadios,
  coinsData,
  onSelectChange,
  selectValue,
  isBuy,
  hasValueName = true,
  menuClassName,
  menuPosition,
  isWidget = false,
  blurMenuEnabled,
  themeColor,
  borderRadius,
}: SelectRCCurrencyCoinsProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputStyles = isHosted ? { transform: `translateX(-${value.length * 9 - 60}px` } : {};

  const renderItem = useCallback(
    (label: string, value: string, name: string, icon: ReactNode, subname?: string) => {
      return (
        <div className={styles.row}>
          <div
            className={clsx(
              styles.flag,
              { [styles.hostedFlag]: isHosted },
              { [styles.hostedFlagMedium]: size === 'medium' },
            )}
          >
            {icon}
          </div>
          {!isHosted && <span>{label}</span>}
          {hasValueName && (
            <span
              className={clsx(styles.valueName, {
                [styles.hostedValueName]: isHosted,
              })}
            >
              <span className={styles.valueLabel}>{name}</span>
              {subname && <span className={styles.subnameLabel}>{subname}</span>}
            </span>
          )}
        </div>
      );
    },
    [isHosted, size, hasValueName],
  );

  const integerAccept = /\d+/g;

  const formatInteger = (v: string) => {
    return v;
  };

  return (
    <div
      className={clsx(styles.container, className, {
        [styles.focused]: isFocused || value,
        [styles.medium]: size === 'medium',
      })}
    >
      {label && (
        <div
          className={clsx(
            styles.labelWrapper,
            labelClassName,
            {
              [styles.labelWrapperMedium]: size === 'medium',
            },
            { [styles.labelWrapperHosted]: isHosted },
          )}
        >
          <label className={styles.label}>{label}</label>
        </div>
      )}
      <SelectRC
        borderRadius={borderRadius}
        themeColor={themeColor}
        menuPosition={menuPosition}
        blurMenuEnabled={blurMenuEnabled}
        valueClassName={styles.selectValue}
        withoutRadios={withoutRadios}
        variant={size === 'large' ? 'cryptoCurrency' : 'cryptoCurrencyMedium'}
        isHosted={isHosted}
        size={size}
        isBuy={isBuy}
        hasValue={!!value}
        className={styles.select}
        menuClassName={menuClassName}
        placeholder="Select Token"
        value={
          selectValue?.icon
            ? {
                label: renderItem(
                  selectValue?.label as string,
                  selectValue?.value as string,
                  selectValue?.name as string,
                  selectValue?.icon,
                ),
                value: selectValue?.value as string,
              }
            : selectValue
        }
        onChange={onSelectChange}
        options={map(coinsData, ({ label, value, icon, rate, name, isUpgrade, subname }) => ({
          value,
          label: renderItem(label as string, value as string, name as string, icon, subname),
          rate,
          name,
          isUpgrade,
        }))}
      />

      <Rifm accept={integerAccept} format={formatInteger} value={value} onChange={onChange}>
        {({ value, onChange }) => (
          <div
            className={clsx(styles.inputWrapper, {
              [styles.hostedInputWrapper]: isHosted,
            })}
          >
            <div
              className={clsx(styles.valueWrapper, {
                [styles.hostedValueWrapper]: isHosted,
                [styles.widgetValueWrapper]: isWidget,
              })}
              style={inputStyles}
            >
              {isHosted && Number(value) !== 0 && selectValue?.value && <span className={styles.equal}>≈</span>}
              <input
                className={clsx(
                  styles.input,
                  {
                    [styles.inputMedium]: size === 'medium',
                  },
                  'bg-white',
                )}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                disabled
              />
            </div>
            {isHosted && Number(value) !== 0 && selectValue?.value && (
              <span className={styles.currencyValue}>{toUpper(String(selectValue?.value))}</span>
            )}
          </div>
        )}
      </Rifm>
    </div>
  );
};

export default SelectRCCurrencyCoins;
