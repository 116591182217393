/* eslint-disable no-undef */
import { getCountries, Country } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import countryToCurrency from 'country-to-currency';
import { filter, find, map, uniqBy } from 'lodash';
import Image from 'next/image';
import { useMemo } from 'react';
import { DEFAULT_COUNTRY_CODE_2 } from '@/constants/default';

export type CountryToCurrencyCodeType = keyof typeof countryToCurrency;
export interface CurrencyCodeWithFlagInterface {
  currency: string;
  flag: JSX.Element | undefined;
  countryCode: Country;
}

const renderFlag = (country: Country) =>
  flags?.[country || DEFAULT_COUNTRY_CODE_2]?.({
    title: country || DEFAULT_COUNTRY_CODE_2,
  });
const getCurrencyByCountryCode = (country: Country) =>
  countryToCurrency?.[(country || DEFAULT_COUNTRY_CODE_2) as CountryToCurrencyCodeType];
const countryCodes = getCountries();

export const currencyCodesWithFlags: CurrencyCodeWithFlagInterface[] = uniqBy(
  filter(
    map(countryCodes, (countryCode) => {
      const currency = getCurrencyByCountryCode(countryCode);
      let flag = renderFlag(countryCode);

      switch (currency) {
        case 'USD':
          flag = renderFlag('US');
          break;
        case 'EUR':
          flag = <Image src="/assets/flagEu.svg" alt="eur" width={20} height={20} priority loading="eager" />;
          break;
        default:
          break;
      }
      return {
        currency: getCurrencyByCountryCode(countryCode),
        flag,
        countryCode,
      };
    }),
    (item) => !!item?.currency,
  ),
  'currency',
);

export const useSelectedCurrency = (value: string) => {
  const selectedCurrency = useMemo(() => {
    return find(currencyCodesWithFlags, { currency: value });
  }, [value]);

  return { selectedCurrency, currencyCodesWithFlags };
};
