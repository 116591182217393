import clsx from 'clsx';
import { trim } from 'lodash';
import React from 'react';
import useDigitInput from 'react-digit-input';
import styles from './InputDigits.module.css';

interface InputDigitsProps {
  className?: string;
  value: string;
  onChange: (v: string) => void;
  autoFocus?: boolean;
}
export const InputDigits = ({ className, onChange, value, autoFocus = true }: InputDigitsProps) => {
  const handleChange = (v: string) => {
    const trimValue = trim(v);
    return onChange(trimValue);
  };

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 6,
    value,
    onChange: handleChange,
  });

  const handleLastInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const input = event.target as HTMLInputElement;
    input.setSelectionRange(input.value.length, input.value.length);
  };

  return (
    <div className={clsx(styles.wrapper, className)}>
      <input inputMode="decimal" className={styles.input} autoFocus={autoFocus} {...digits[0]} />
      <input inputMode="decimal" className={styles.input} {...digits[1]} />
      <input inputMode="decimal" className={styles.input} {...digits[2]} />
      <span className={styles.item}>-</span>
      <input inputMode="decimal" className={styles.input} {...digits[3]} />
      <input inputMode="decimal" className={styles.input} {...digits[4]} />
      <input inputMode="decimal" className={styles.input} {...digits[5]} onKeyUp={handleLastInputKeyUp} />
    </div>
  );
};
